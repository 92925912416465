import React from 'react';
import './AccountAccess.scss';
import SiteLinks, {
  DumbSiteLinks,
} from 'storefront/components/Footer/SiteLinks/siteLinks';
import { TERMS_N_COND } from 'client/common/components/VerifyAccountMFA/constants';

const AccountAccess = (props) => {
  const {
    handleChange,
    handleSubmit,
    displayError,
    loading,
    errorMessage,
    showForgotPwModal,
    checked,
    toggleCheck,
    isPanel,
    brand,
    email,
    navpath,
    params,
    NMO_LOYALTY_NEW,
  } = props;
  const { optParam } = params || {};
  const TnCURL = optParam === 'verify'
    ? TERMS_N_COND
    : 'https://www.neimanmarcus.com/assistance/assistance.jsp?itemId=cat33940739';

  return (
    <span className={isPanel ? 'panel-wrapper' : ''}>
      <div
        className={
          optParam !== 'verify'
            ? 'account-access-wrapper'
            : 'account-access-wrapper mfa-verify'
        }
      >
        <form>
          <div className="form">
            <div className="form-content">
              <div className="sign-in-content">
                <h2 id="welcome-text">
                  {optParam === 'verify' ? 'Thank You' : 'Welcome Back!'}
                </h2>
                {optParam === 'verify' ? (
                  <p>
                    Almost there! Sign in and confirm your identity to complete
                    account set-up.
                  </p>
                ) : null}
                <div className="input-text">Email</div>
                <input
                  id="email-input-field"
                  name="email"
                  value={email}
                  autoComplete="email"
                  onChange={handleChange}
                  placeholder={isPanel ? 'EMAIL' : ''}
                  aria-label="email"
                />
                <div className="input-text">Password</div>
                <input
                  type="password"
                  id="password-input-field"
                  name="password"
                  onChange={handleChange}
                  placeholder={isPanel ? 'PASSWORD' : ''}
                  aria-label="password"
                  autoComplete="current-password"
                />
                {displayError && (
                  <div className="login-text-error" aria-live={errorMessage}>
                    {errorMessage}
                  </div>
                )}
                {optParam !== 'verify' && (
                  <div className="forgot-password-container-login">
                    <button
                      aria-label="Forgot password?"
                      id="hyperlinkButtonLogin"
                      type="button"
                      onClick={showForgotPwModal}
                    >
                      Forgot Password?
                    </button>
                  </div>
                )}
                <button
                  type="submit"
                  id="signin-button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? 'SIGNING IN...' : 'SIGN IN'}
                </button>
                <div
                  className={`kmsi-container ${
                    optParam === 'verify' ? 'input-text' : ''
                  }`}
                >
                  <input
                    id="kmsi-checkbox"
                    type="checkbox"
                    checked={checked}
                    onChange={toggleCheck}
                    aria-label="Keep me signed in"
                  />
                  <span className="kmsi-text">Keep me signed in</span>
                  <span className="kmsi-disclaimer">
                    By continuing, you agree to our
{' '}
                    <a
                      href={
                        brand === 'HC'
                          ? 'https://www.horchow.com/assistance/assistance.jsp?itemId=cat16060787'
                          : TnCURL
                      }
                    >
                      Privacy Policy
                    </a>
{' '}
                    and
{' '}
                    <a
                      href={
                        brand === 'HC'
                          ? 'https://www.horchow.com/assistance/assistance.jsp?itemId=cat16060787'
                          : TnCURL
                      }
                    >
                      Terms & Conditions.
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {optParam !== 'verify' ? (
              <div className="form-content">
                <div className="register-content">
                  <h2>Register with Us</h2>
                  <ul className="registration-text">
                    {!NMO_LOYALTY_NEW ? (
                      <>
                        <li>Save your information for faster checkout</li>
                        <li>View order history and easily track purchases</li>
                        <li>Enjoy a more personalized experience</li>
{' '}
                      </>
                    ) : (
                      <>
                        <li>Earn points and redeem for rewards </li>
                        <li> Access to exclusive offers and events </li>
                        <li>Enjoy a more personalized experience</li>
                        <li> Save your information for faster checkout </li>
                        <li> View order history and easily track purchases</li>
                      </>
                    )}
                  </ul>
                  <a
                    id="accountRegisterAnchor"
                    href={
                      navpath
                        ? `/register?navpath=${navpath}`
                        : '/register'
                    }
                  >
                    REGISTER NOW
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </form>
        {isPanel ? (
          <DumbSiteLinks
            accountAccess="accountAccess"
            country="US"
            optOutLinkToggle
            isPanel
          />
        ) : (
          <SiteLinks accountAccess="accountAccess" />
        )}
      </div>
    </span>
  );
};

export default AccountAccess;
