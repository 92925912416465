import React, { Component } from 'react';
import window from 'window-or-global';
import Store from '../Store/Store';
import { Consumer } from '../../PanelContext';
import {
  sortStoreData,
  getStoreJson,
  ENTER_KEYCODE,
  getBopsList,
  getStoreCookie,
} from '../utilities';
import './Bops.scss';

class Bops extends Component {
  constructor(props) {
    super(props);
    const { storeSelected } = props;
    this.state = {
      locationSearch: null,
      initialStoreSelected: storeSelected,
      storeData: [],
      storeStatus: 'resolved',
      isATG: window.location.href.indexOf('.jsp') > -1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleAddToBag = this.handleAddToBag.bind(this);
  }

  componentDidMount() {
    const { location, bopsSku } = this.props;
    bopsSku && this.getBopsData(location, bopsSku);
    this.initialStore();
  }

  componentWillUnmount() {
    const { isATG, atgStoreId } = this.state;
    const { callback } = this.props;
    if (isATG && atgStoreId) callback(atgStoreId);
  }

  getBopsData(location, sku) {
    const { storeSelected } = this.props;
    this.setState({
      storeStatus: 'loading',
      initialStoreSelected: undefined,
    });
    getBopsList(location, sku)
      .then(({ data: storeList }) => {
        this.setState({
          storeStatus: storeList.length ? 'resolved' : 'failed',
        });
        storeList.forEach(({ storeNumber, curbsideSkuAvailability }) => {
          getStoreJson(storeNumber).then(({ data: json }) => {
            const store = { ...json, skuAvailability: curbsideSkuAvailability };
            const { storeData } = this.state;
            this.setState({
              storeData: [...storeData, store],
            });
            if (storeSelected && storeSelected.id === store.id) {
              this.setState({ initialStoreSelected: store });
            }
          });
        });
      })
      .catch(() => {
        this.setState({ storeStatus: 'failed' });
      });
  }

  initialStore() {
    const { storeData = [], setStoreSelected } = this.props;
    const storeCookie = getStoreCookie();
    if (storeCookie.id) {
      const storeDataExists = storeData.filter(
        (store) => store.id === storeCookie.id,
      )[0];
      if (storeDataExists) {
        this.setState({ initialStoreSelected: storeDataExists });
        setStoreSelected(storeDataExists);
      } else {
        getStoreJson(storeCookie.number).then(({ data }) => {
          this.setState({ initialStoreSelected: data });
          setStoreSelected(data);
        });
      }
    }
  }

  handleChange(e) {
    this.setState({ locationSearch: e.target.value });
  }

  handleKeyDown(e) {
    if (e.which === ENTER_KEYCODE) {
      e.stopPropagation();
      this.handleSearch();
    }
  }

  handleSearch() {
    const { locationSearch } = this.state;
    const { bopsSku } = this.props;
    if (locationSearch.length) {
      this.setState({ storeData: [] });
      bopsSku && this.getBopsData(locationSearch, bopsSku);
    }
  }

  handleAddToBag(storeNumber) {
    const { callback, togglePanel } = this.props;
    this.setState({ atgStoreId: storeNumber });
    callback(storeNumber);
    togglePanel();
  }

  renderStores() {
    const { initialStoreSelected, storeData } = this.state;
    const { setStoreSelected, storeSelected, NEW_STORES_UI } = this.props;
    const sortedStoreData = sortStoreData(storeData, initialStoreSelected);
    return sortedStoreData.map((store, i) => {
      const currentStore = storeSelected
        ? store.id === storeSelected.id
        : false;
      const inventoryAvailable = store?.skuAvailability?.inventoryAvailable;
      const addToCartText = store?.skuAvailability?.curbsidePickupMessage;
      return (
        <div key={i} className="panel__bops-store">
          <Store store={store} details overview={false} NEW_STORES_UI={NEW_STORES_UI} />
          <button
            aria-label={addToCartText}
            className={
              inventoryAvailable ? 'panel__bops-inStock' : 'panel__bops-oos'
            }
            onClick={() => {
              this.handleAddToBag(store.id.split('/')[0]);
              window.YNStoreCallback && window.YNStoreCallback(store);
            }}
          >
            {addToCartText}
          </button>
          {currentStore ? (
            <button className="panel__bops-selected">Your Current Store</button>
          ) : (
            <button
              id="panel-set-button"
              className="panel__bops-set"
              onClick={() => {
                setStoreSelected(store);
                window.YNStoreCallback && window.YNStoreCallback(store);
                this.setState({ atgStoreId: store.id.split('/')[0] });
              }}
            >
              Set as your current store
            </button>
          )}
        </div>
      );
    });
  }

  render() {
    const { locationSearch, storeData, storeStatus } = this.state;
    const { location } = this.props;
    const hasLocationData = location.length && storeData.length;
    const placeholder = hasLocationData
      ? `Showing near ${location}`
      : 'Enter City, State or Zip';
    return (
      <div className="panel__bops">
        <h1 className="panel__bops-title">Pick Up at a Store</h1>
        <div className="panel__bops-search">
          <input
            className="panel__bops-search-input"
            placeholder={placeholder}
            type="text"
            onFocus={(e) => {
              if (hasLocationData && locationSearch === null) e.target.value = placeholder;
              e.target.placeholder = 'Enter City, State or Zip';
            }}
            onBlur={(e) => {
              e.target.placeholder = placeholder;
            }}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            value={locationSearch}
          />
          <button
            className="panel__bops-search-button"
            onClick={this.handleSearch}
          >
            Search
          </button>
        </div>
        {storeStatus === 'resolved' && this.renderStores()}
        {storeStatus === 'failed' && (
          <span className="panel__bops-error">
            We currently have no results for this search. Try changing the city,
            state, zip.
          </span>
        )}
        {storeStatus === 'loading' && (
          <span className="panel__bops-loading">
            <div className="panel__spinner" />
          </span>
        )}
      </div>
    );
  }
}
const ConnectedBops = (props) => (
  <Consumer>
    {({
      location,
      setLocation,
      setStoreSelected,
      storeSelected,
      callback,
      togglePanel,
      bopsSku = '',
    }) => (
      <Bops
        {...props}
        location={location}
        setLocation={setLocation}
        setStoreSelected={setStoreSelected}
        storeSelected={storeSelected}
        callback={callback}
        togglePanel={togglePanel}
        bopsSku={bopsSku}
      />
    )}
  </Consumer>
);
export default ConnectedBops;
