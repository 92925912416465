import Auth from '@aws-amplify/auth';
import IconArrowRight from 'assets/images/icon-arrow-right.svg';
import IncircleLogo from 'assets/images/incircle-logo.svg';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import httpWithLogging from 'universal/http-client';
import { getAuthApiData } from '../../../../../utilities/amplifyUtils';
import { GeneralPanelSkeleton } from '../Skeleton/PanelSkeleton';
import './InCircleNmCreditCard.scss';

const CLASS_PREFIX = 'incircle_nm_credit_card';

const InCircleNmCreditCard = () => {
  const [pointsTiersSummary, setPointsTiersSummary] = useState({});
  const [givCardData, setGivCardData] = useState([]);
  const [isLoadingPointsTiersSummary, setIsLoadingPointsTiersSummary] = useState(false);
  const [isLoadingGivCard, setIsLoadingGivCard] = useState(false);

  const tierNames = {
    CIRCLE_1: 'Circle One',
    CIRCLE_2: 'Circle Two',
    CIRCLE_3: 'Circle Three',
    CIRCLE_4: 'Circle Four',
    CIRCLE_5: 'Circle Five',
    CIRCLE_6: 'Circle Six',
    PRESIDENT: 'President',
    CHAIRMAN: 'Chairman',
  };

  const getTierName = (tierCode) => {
    return tierNames[tierCode];
  };

  const ProgressBar = ({ points, totalPoints }) => {
    // Calculate percentage of completion
    const percentage = totalPoints > 0 ? (points / totalPoints) * 100 : 100;

    return (
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${percentage}%` }} />
      </div>
    );
  };

  const lambdaHost = window.sessionStorage.getItem('lambdaHost');
  const fastlyHost = window.sessionStorage.getItem('fastlyUCAHost') || lambdaHost;

  const GetPointsTiersSummary = async () => {
    setIsLoadingPointsTiersSummary(true);

    const { ucaId, headers } = await getAuthApiData();

    const authData = await Auth.currentSession();
    const user = authData?.idToken?.payload;
    const loyaltyProfileId = user['custom:loyaltyProfileId'];

    const body = {
      ucaId,
      loyaltyProfileId,
    };
    const requestApi = httpWithLogging({}, 6000, true);
    await requestApi.post(`${fastlyHost}/loyalty/api/v1/profile/ucaId/${ucaId}/summary`, body, { headers })
      .then(({ data }) => {
        setPointsTiersSummary(data);
        setIsLoadingPointsTiersSummary(false);
      })
      .catch((error) => {
        setPointsTiersSummary({});
        setIsLoadingPointsTiersSummary(false);
        console.log(error);
      });
  };

  const GetGivCard = async () => {
    setIsLoadingGivCard(true);

    const { profileId, headers: { Authorization } } = await getAuthApiData();
    const headersUpdated = {
      Authorization,
      'X-NMG-CARD-CLASS': 'ALL',
    };

    await axios.get(`${fastlyHost}/customer-data/v1/${profileId}/giv-cards`, { headers: headersUpdated })
      .then(({ data }) => {
        setGivCardData(data);
        setIsLoadingGivCard(false);
      })
      .catch((error) => {
        setGivCardData([]);
        setIsLoadingGivCard(false);
        console.log(error);
      });
  };

  useEffect(() => {
    GetPointsTiersSummary();
    GetGivCard();
  }, []);

  // givCardData all last_ptd_balance sum
  let YourPointsSum = 0;

  givCardData?.forEach((item) => {
    YourPointsSum += parseFloat(item.last_ptd_balance);
  });

  const totalPoints = pointsTiersSummary?.totalPointsToNextReward;
  const points = pointsTiersSummary?.pointBalance;

  // Calculate points needed for next reward
  const pointsToNextReward = totalPoints - points;


  const checkTierCode = pointsTiersSummary?.tierCode === 'CIRCLE_1'; // condition checking for tire 2 or tire 1
  const showBenefitsSection = !checkTierCode;

  // true if auto redemption false if manual redemption
  const autoRewardOInFlag = pointsTiersSummary?.autoRewardOptInInd;

  return (
    <div className={`${CLASS_PREFIX}__container`}>
      <div className={`${CLASS_PREFIX}__your-status-card`}>
        <img src={IncircleLogo} alt="Incircle_Logo" className={`${CLASS_PREFIX}__logo`} />
        {
          isLoadingPointsTiersSummary
            ? <GeneralPanelSkeleton skipImage numOfMenuItems={2} />
            : Object.keys(pointsTiersSummary).length !== 0 && (
              <div className={`${CLASS_PREFIX}__your-status`}>
                Your Status:
                {' '}
                {getTierName(pointsTiersSummary?.tierCode)}
              </div>
            )
        }
      </div>
      <div className={`${CLASS_PREFIX}__status-card-outer`}>
        {
          isLoadingGivCard
            ? <GeneralPanelSkeleton skipImage numOfMenuItems={3} />
            : givCardData.length !== 0 && (
              <>
                <div className={`${CLASS_PREFIX}__your-point-cards`}>
                  <div className={`${CLASS_PREFIX}__your-point-cards-title`}>YOUR POINT CARDS</div>
                  <div className={`${CLASS_PREFIX}__your-point-cards-subtitle`}>Point cards can be applied at checkout</div>
                  <div className={`${CLASS_PREFIX}__your-point-cards-subtitle`}>
                    <div className={`${CLASS_PREFIX}__your-point-cards-amount-inside`}>
                      $
                      {parseFloat(YourPointsSum).toFixed()}
                    </div>
                  </div>
                </div>
                <div className={`${CLASS_PREFIX}__border`} />
              </>
            )
        }
        {
          isLoadingPointsTiersSummary
            ? <GeneralPanelSkeleton skipImage numOfMenuItems={3} />
            : Object.keys(pointsTiersSummary).length !== 0
            && (
              <>
                <div className={`${CLASS_PREFIX}__your-points-div`}>
                  <div className={`${CLASS_PREFIX}__your-points`}>
                    YOUR POINTS
                  </div>
                  <div className={`${CLASS_PREFIX}__points-digits`}>
                    {points?.toLocaleString()}
                  </div>
                  <div className={`${CLASS_PREFIX}__progress-bar`}>
                    <ProgressBar points={points} totalPoints={totalPoints} />
                  </div>
                  <div className={`${CLASS_PREFIX}__points-inside-heading`}>
                    {autoRewardOInFlag
                      ? `${pointsToNextReward.toLocaleString()} POINTS UNTIL YOUR NEXT $100 POINT CARD`
                      : 'MANUAL REDEMPTION IS ON FOR THIS ACCOUNT. PLEASE CALL 888.462.4725 TO REDEEM YOUR POINT CARDS.'}
                  </div>
                </div>
              </>
            )
        }
        {isLoadingPointsTiersSummary ? (
          <GeneralPanelSkeleton skipImage numOfMenuItems={2} />
        ) : Object.keys(pointsTiersSummary).length !== 0 && (
          showBenefitsSection && (
            <>
              <div className={`${CLASS_PREFIX}__border`} />
              <div className={`${CLASS_PREFIX}__your-benefits-div`}>
                <div className={`${CLASS_PREFIX}__your-benefits`}>
                  YOUR BENEFITS
                </div>
                <div className={`${CLASS_PREFIX}__your-benefits-sub-title`}>
                  Free Shipping
                </div>
              </div>
            </>
          )
        )}
      </div>
      <div
        className={`${CLASS_PREFIX}__in-circle-details-div`}
        onClick={() => {
          window.location.href = '/my/NMCreditCard';
        }}
      >
        <div className={`${CLASS_PREFIX}__in-circle-details`}>
          InCircle Details
        </div>
        <div>
          <img src={IconArrowRight} alt="IconArrowRight" className={`${CLASS_PREFIX}__logos`} />
        </div>
      </div>
    </div>
  );
};

export default InCircleNmCreditCard;
