export const CIRCLE_REWARDS = {
  2: [
    'Free 2 day shipping for online and catalog orders',
    'A double point day of your choice',
  ],
  3: [
    'A $25 Perk Card to use toward store services',
    'Free gift packaging online and in-store',
  ],
  4: [
    'Access to the InCircle Concierge to arrange your inspiring adventure, secure those hard-to-get tickets, and so much more',
  ],
  5: [
    'A $50 Perk Card to use toward store services',
  ],
  6: [
    '$100 Perk Card to use toward store services',
    'A customized Fitting Room Experience complete with light bites and curated product selections',
  ],
  7: [
    'Earn 5 points for virtually every dollar spent',
    '$150 Perk Card to use toward store services',
  ],
  8: [
    'Complimentary store services, including: monogramming, alterations, shoe and handbag repair, fur services, local courier delivery, in-store dining, valet, precious jewelry upkeep and engraving, monogramming, salon services, eyeglass frame and lens services',
    'A specially curated travel experiences, featuring one-of-a-kind excursions to extraordinary destinations around the world',
  ],
};

export const CIRCLE_NAMES = [
  null,
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'President\'s Circle',
  'Chairman\'s Circle',
];
