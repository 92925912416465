import React from 'react';
import { PHONE_MATCH_REGEXP } from './constants';
import './MFAComponentStyles.scss';

const PhoneInput = ({ phoneNumber, onPhoneValuesChange, hasError }) => {
  const validatePhoneNumber = (phoneValue) => {
    const cleaned = `${phoneValue}`.replace(/\D/g, '');
    const match = cleaned.match(PHONE_MATCH_REGEXP);

    if (match) {
      const number = [match[2], '-', match[3], '-', match[4]].join('');

      return onPhoneValuesChange({ phone: number, phoneValid: true });
    }

    return onPhoneValuesChange({ phone: cleaned });
  };

  const handlePhoneNumberChange = (e) => {
    validatePhoneNumber(e?.target?.value);
  };

  return (
    <div className="phone-input-widget">
      <div className="phone-input-widget__container">
        <div className="phone-input-widget__wrapper">
          <input
            maxLength="11"
            type="tel"
            className={hasError ? 'phone-input-widget__field validationError' : 'phone-input-widget__field'}
            name="phone"
            aria-label="phone number input"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={handlePhoneNumberChange}
            placeholder=" "
            disabled
            tabIndex="-1"
          />
          {/* <label htmlFor="phone">Phone Number</label> */}
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
