/* eslint-disable object-curly-newline */
import React from 'react';
import Modal from 'react-modal';
import { Consumer } from '../../PanelContext';
import './Panel.scss';

const Panel = ({ children }) => (
  <Consumer>
    {({ isOpen, togglePanel, onAfterOpen, onAfterClose }) => (
      <Modal
        ariaHideApp={false}
        className="panel panel_right-direction"
        closeTimeoutMS={500}
        contentLabel="Your Neiman's Panel"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        onRequestClose={togglePanel}
        overlayClassName="panel__overlay"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldReturnFocusAfterClose
      >
        {children}
      </Modal>
    )}
  </Consumer>
);

export default Panel;
