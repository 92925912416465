import React, { Component } from 'react';
import { window } from 'window-or-global';
import { Consumer } from '../../PanelContext';
import { ENTER_KEYCODE, setSelectedZip } from '../utilities';
import './UpdateZip.scss';

class UpdateZip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zipSearch: '',
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleChange(e) {
    this.setState({ zipSearch: e.target.value });
  }

  handleKeyDown(e, togglePanel, callback) {
    if (e.which === ENTER_KEYCODE) {
      e.stopPropagation();
      this.handleUpdate(togglePanel, callback);
    }
  }

  handleUpdate(togglePanel, callback) {
    const { zipSearch } = this.state;
    const validZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipSearch);

    if (validZip) {
      setSelectedZip(zipSearch);
      callback && callback(zipSearch);
      window.YNZipCallback && window.YNZipCallback();
      togglePanel();
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  }

  render() {
    const { zipSearch, error } = this.state;

    return (
      <Consumer>
        {({ togglePanel, callback }) => (
          <div className="panel__updateZip">
            <h1 className="panel__updateZip-title">Update Location</h1>
            <div className="panel__updateZip-search">
              <input
                placeholder="Enter Zip"
                type="text"
                onKeyDown={(e) => this.handleKeyDown(e, togglePanel, callback)}
                onChange={this.handleChange}
                value={zipSearch}
              />
            </div>
            {error && (
              <span className="panel__updateZip-error">
                Please enter a valid zip code.
              </span>
            )}
            <button
              onClick={() => this.handleUpdate(togglePanel, callback)}
              className="panel__updateZip-cta"
            >
              Update
            </button>
          </div>
        )}
      </Consumer>
    );
  }
}

export default UpdateZip;
