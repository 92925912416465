/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { getInitials, getSaData, goToApptUrl } from '../utilities';
import defaultBanner from '../../assets/defaultBanner.png';
import PromiseLoader from '../../hocs/PromiseLoader';
import { GeneralPanelSkeleton } from '../Skeleton/PanelSkeleton';
import { Consumer } from '../../PanelContext';
import './StyleAdvisor.scss';

const Advisor = ({ sa, goToPage, expanded, toggles, bagError, handleBag }) => {
  const {
    YOUR_NEIMANS_SA_INTERACTION,
    YOUR_NEIMANS_SA_SEND_BAG,
    YOUR_NEIMANS_SA_EMAIL,
  } = toggles;
  const { name, phoneNumber, imageUrl, storeId } = sa;
  const saSelected = { saSelected: sa };

  return (
    <>
      <div
        className="panel__advisor-associate"
        onClick={() => !expanded && goToPage('saPage', saSelected)}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" />
        ) : (
          <span className="panel__advisor-initials">{getInitials(name)}</span>
        )}
        <div className="panel__advisor-message">
          <span>{`${name}, `}</span>
          <span>Your Style Advisor</span>
        </div>
        {!expanded && (
          <button
            aria-label={`${name}, Your Style Advisor`}
            onClick={(e) => {
              e.stopPropagation();
              goToPage('saPage', saSelected);
            }}
            className="panel__advisor-forward"
          />
        )}
      </div>
      {expanded && (
        <>
          <span className="panel__advisor-link">
            <a href={null} onClick={() => goToApptUrl(storeId)}>
              Book an appointment
            </a>
          </span>
          {YOUR_NEIMANS_SA_INTERACTION && (
            <>
              {YOUR_NEIMANS_SA_EMAIL && (
                <span
                  className="panel__advisor-link"
                  onClick={() => goToPage('emailPage', saSelected, true)}
                >
                  Email
                  <button
                    aria-label="Email Your Style Advisor"
                    className="panel__advisor-forward"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToPage('emailPage', saSelected, true);
                    }}
                  />
                </span>
              )}
              {phoneNumber && (
                <span className="panel__advisor-sms">
                  <a href={`sms:${phoneNumber}`} tabIndex="-1">
                    Text
                    <button
                      aria-label="Send Text to Your Style Advisor"
                      className="panel__advisor-forward"
                    />
                  </a>
                </span>
              )}
            </>
          )}
          {YOUR_NEIMANS_SA_SEND_BAG && (
            <div
              className="panel__advisor-link panel__advisor-bag"
              onClick={() => handleBag(saSelected)}
            >
              <button
                aria-label="Send Shopping Bag to Your Style Advisor"
                className="panel__advisor-forward-float"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBag(saSelected);
                }}
              />
              <span className="panel__advisor-bag-message">
                Send shopping bag
              </span>
              {bagError && (
                <span
                  aria-live="assertive"
                  className="panel__advisor-bag-error"
                >
                  Your shopping bag is empty
                </span>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const defaultView = (
  <>
    <div className="panel__advisor-banner">
      <img src={defaultBanner} alt="Your Neiman's Banner" aria-hidden="true" />
    </div>
    <span className="panel__advisor-link">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.neimanmarcus.com/quiz/style-advisor"
      >
        Match with a style advisor
      </a>
    </span>
    <span className="panel__advisor-link">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://stores.neimanmarcus.com/bookings"
      >
        Book an appointment with a style advisor
      </a>
    </span>
  </>
);

class StyleAdvisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bagError: false,
    };

    this.handleBag = this.handleBag.bind(this);
    this.getSaPromise = () => new Promise((resolve, reject) => {
      const { setSaData, name, saData, toggles } = props;
      const { YOUR_NEIMANS_DEFAULT_SA, SALES_ASSOCIATES_V2 } = toggles;
      if (name && (!saData.length && !YOUR_NEIMANS_DEFAULT_SA)) {
        const saCallback = () => getSaData(SALES_ASSOCIATES_V2).then(({ data, status }) => {
          setSaData(data || []);
          if ((data || status === 204) && typeof sessionStorage !== 'undefined') {
            sessionStorage.setItem(`YourNeimansSa${name}`, JSON.stringify(data || []));
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
          setSaData([]);
        });
        Auth.currentSession().then(saCallback).catch((err) => {
          resolve(err);
          return err;
        });
      } else {
        resolve();
      }
    });
  }

  getLoadingTemplate = () => (
    <div className="panel__advisor">
      <h1 className="panel__advisor-title">{this.props.title}</h1>
      <GeneralPanelSkeleton numOfMenuItems={2} />
    </div>
  )

  handleBag(saSelected) {
    const { bagCount = 0, goToPage } = this.props;

    if (bagCount > 0) {
      goToPage('bagPage', saSelected, true);
    } else {
      this.setState({ bagError: true });
    }
  }

  render() {
    const {
      goToPage,
      pageStack,
      saData,
      saSelected,
      title,
      toggles,
      name,
      defaultLoading,
    } = this.props;
    const { YOUR_NEIMANS_DEFAULT_SA } = toggles;
    const { bagError } = this.state;

    const data = pageStack[pageStack.length - 1] === 'saPage'
      ? [saSelected]
      : saData;

    return (
      <PromiseLoader
        loadingTemplate={this.getLoadingTemplate()}
        defaultLoading={defaultLoading || (name && (!saData.length && !YOUR_NEIMANS_DEFAULT_SA))}
        promise={this.getSaPromise}
      >
        <div className="panel__advisor">
          <h2 className="panel__advisor-title">{title}</h2>
          {
            {
              0: defaultView,
              1: data.map((sa, i) => (
                <Advisor
                  key={i}
                  sa={sa}
                  goToPage={goToPage}
                  expanded
                  toggles={toggles}
                  bagError={bagError}
                  handleBag={this.handleBag}
                />
              )),
              2: data.map((sa, i) => (
                <Advisor
                  key={i}
                  sa={sa}
                  goToPage={goToPage}
                  toggles={toggles}
                  bagError={bagError}
                  handleBag={this.handleBag}
                />
              )),
              3: data.map((sa, i) => (
                <Advisor
                  key={i}
                  sa={sa}
                  goToPage={goToPage}
                  toggles={toggles}
                  bagError={bagError}
                  handleBag={this.handleBag}
                />
              )),
            }[name ? data.length : 0]
          }
        </div>
      </PromiseLoader>
    );
  }
}

const ConnectedStyleAdvisor = (props) => (
  <Consumer>
    {({
      bagCount,
      goToPage,
      name,
      pageStack,
      saData,
      saSelected,
      setSaData,
      toggles,
    }) => (
      <StyleAdvisor
        {...props}
        bagCount={bagCount}
        goToPage={goToPage}
        name={name}
        pageStack={pageStack}
        saData={saData}
        saSelected={saSelected}
        setSaData={setSaData}
        toggles={toggles}
      />
    )}
  </Consumer>
);

export default ConnectedStyleAdvisor;
