/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { Consumer } from '../../PanelContext';
import {
  handleProtectedClick,
  incircleModule,
  getAuthApiData,
} from '../../../../../utilities/amplifyUtils';
import { normalizeString } from '../../../../../profile/components/OrderHistoryDT/OrderHistoryUtils';
import { CIRCLE_NAMES } from '../InCircle/constants';
import PromiseLoader from '../../hocs/PromiseLoader';
import { GeneralPanelSkeleton } from '../Skeleton/PanelSkeleton';
import {
  fetchPromoCardData,
  getProfileData,
  hasExpiringOffer,
  getOfferNotificationCount,
  determineOfferTemplate,
  hasPromoCardExpiringOffer,
  determinePromocardTemplate,
  hasIncircleGiftCardExpiringOffer,
  determineIncircleCardTemplate,
} from '../utilities';
import AccountLink from './AccountLink';
import './Account.scss';

const getPrimaryIncircleDetails = (goToPage) => {
  const parsedIncircleData = JSON.parse(sessionStorage.getItem('incircleData'));
  if (!parsedIncircleData?.length || !parsedIncircleData[0]?.level) return null;
  const { level, redeem_avail: redeemAvail } = parsedIncircleData[0];
  const levelWord = normalizeString(CIRCLE_NAMES[level]);

  return (
    <AccountLink handleClick={() => goToPage('inCirclePage')} label="Go to Incircle Page">
      <div className="panel__account--arrowContainer panel__account--incircle">
        Incircle -
        {level && level < 7 && ' Circle'}
        {` ${levelWord} ${redeemAvail} Points`}
        <button className="panel__account--arrowRight" />
      </div>
    </AccountLink>
  );
};

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offers: null,
      promoCards: null,
      offerNotificationCount: 0,
      hasExpiringOffer: false,
      promoCardNotificationCount: 0,
      hasPromoCardExpiringOffer: false,
      inCircleCards: null,
      incircleCardNotificationCount: 0,
      hasIncircleCardExpiringOffer: false,
      userIncircleMemberCheck: null,
    };
  }

  componentDidMount() {
    this.fetchUserIncircleMember();
  }

  fetchUserIncircleMember = async () => {
    try {
      const authData = await Auth.currentSession();
      const user = authData?.idToken?.payload;
      const userIncircleMemberCheck = user['custom:loyaltyProfileId'];
      this.setState({ userIncircleMemberCheck });
    } catch (error) {
      console.error('Error fetching loyalty profile ID:', error);
    }
  };

  getAccountData = (PROMO_NOTIFICATIONS, GIFT_CARD_NOTIFICATIONS, NMO_LOYALTY_NEW) => {
    if (NMO_LOYALTY_NEW) return Promise.resolve();
    return new Promise((resolve, reject) => {
      const { isAuthenticated } = getProfileData();
      if (!isAuthenticated) {
        this.setState({ promoCards: 0, offers: 0 });
        reject();
      }

      let numFinished = 0;

      const checkIfFinished = (count = 1, ...args) => {
        numFinished += count;
        if (numFinished >= 3) {
          resolve(...args);
        }
      };

      incircleModule
        .getIncircleData()
        .then((data) => {
          this.props.setIncircleData(data);
          checkIfFinished();
        })
        .catch((e) => reject(e));

      getAuthApiData()
        .then((authData) => {
          this.getOffersData(authData)
            .then(({ data }) => {
              const filteredOffers = data.filter(
                (ele) => ele.offerFlag !== 'Evergreen'
              );
              this.setState({ offers: filteredOffers.length });
              if (PROMO_NOTIFICATIONS) {
                const offerNotificationCount = getOfferNotificationCount(
                  filteredOffers,
                  authData.profileId
                );
                this.setState({
                  offerNotificationCount,
                  hasExpiringOffer: hasExpiringOffer(filteredOffers),
                });
              }
            })
            .catch(() => {
              this.setState({ offers: 0 });
            })
            .finally(checkIfFinished);
          this.getIncirclePromoCardData(authData);
          this.getPromoCardData(authData, GIFT_CARD_NOTIFICATIONS)
            .then((data) => {
              window?.sessionStorage.setItem(
                'promoCards',
                JSON.stringify(data)
              );
              this.setState({
                promoCards: data.length,
                hasPromoCardExpiringOffer: hasPromoCardExpiringOffer(data),
              });
            })
            .catch((e) => {
              return e;
            })
            .finally(checkIfFinished);
        })
        .catch(() => {
          this.setState({ promoCards: 0, offers: 0 });
          checkIfFinished(2);
        });
    });
  };

  getOffersData = ({ fastlyHost, ucaId, headers }) => {
    return axios.get(
      `${fastlyHost}/promotions/v1/profiles/${ucaId}/special-offers/cached`,
      { headers }
    );
  };

  getIncirclePromoCardData = async ({
    fastlyHost,
    profileId,
    headers: { Authorization },
  }) => {
    const headers = {
      Authorization,
      'X-NMG-CARD-CLASS': 'ALL',
    };
    try {
      const { data } = await axios.get(
        `${fastlyHost}/customer-data/v1/${profileId}/giv-cards`,
        { headers }
      );
      const cachedIncircleData = JSON.parse(
        window.localStorage.getItem(`incircleData.${profileId}`)
      );
      this.setState({
        inCircleCards: data.length,
        hasIncircleCardExpiringOffer: hasIncircleGiftCardExpiringOffer(data),
      });
      if (cachedIncircleData?.length > 0) {
        const newIncirclGiftData = data.reduce(
          (acc, { ki }) => (cachedIncircleData.findIndex((card) => card.ki === ki) === -1
            ? acc + 1
            : acc),
          0
        );
        if (newIncirclGiftData > 0) {
          this.setState({
            incircleCardNotificationCount: newIncirclGiftData,
          });
        }
      } else {
        this.setState({
          incircleCardNotificationCount: data.length,
        });
      }
    } catch (error) {
      this.setState({ inCircleCards: 0 });
    }
  };

  getPromoCardData = (
    { fastlyHost, profileId, headers },
    GIFT_CARD_NOTIFICATIONS
  ) => {
    return new Promise((resolve, reject) => {
      const cachedCardData = window?.sessionStorage.getItem('promoCards');
      const cachedCardDataLs = window?.localStorage.getItem(
        `${profileId}.lastViewedPromoCards`
      );
      if (cachedCardData && !GIFT_CARD_NOTIFICATIONS) {
        const promoCards = JSON.parse(cachedCardData)?.length;
        this.setState({ promoCards });
      }
      fetchPromoCardData(fastlyHost, profileId, headers)
        .then(({ data }) => {
          if (GIFT_CARD_NOTIFICATIONS) {
            const cachedPromoCardsLs = JSON.parse(cachedCardDataLs);
            if (cachedPromoCardsLs && cachedPromoCardsLs.length > 0) {
              const newGiftCardPromo = data.reduce(
                (acc, { ki }) => (cachedPromoCardsLs.findIndex((card) => card.ki === ki) === -1
                  ? acc + 1
                  : acc),
                0
              );
              if (newGiftCardPromo > 0) {
                this.setState({
                  promoCardNotificationCount: newGiftCardPromo,
                });
              }
            } else {
              this.setState({ promoCardNotificationCount: data.length });
            }
          }
          resolve(data);
        })
        .catch(() => {
          this.setState({ promoCards: 0 });
          reject();
        });
    });
  };

  getLoadingTemplate = (
    { PANEL_OFFERS = false, PROMOGC_PANEL = false },
    name
  ) => {
    let menuItems = 4;
    if (name) menuItems += PANEL_OFFERS + PROMOGC_PANEL;
    return (
      <div className="panel__account">
        <h1 className="panel__account-title">Your Account</h1>
        <GeneralPanelSkeleton skipImage numOfMenuItems={menuItems} />
      </div>
    );
  };

  getOffersTemplate = (PROMO_NOTIFICATIONS) => {
    const { offerNotificationCount, offers, hasExpiringOffer } = this.state;
    const offerTemplate = PROMO_NOTIFICATIONS
      ? determineOfferTemplate(offerNotificationCount, offers, hasExpiringOffer)
      : `Offers - ${offers || 'None'} Available`;
    return (
      <AccountLink
        handleClick={() => {
          window.location.href = '/specialoffers';
        }}
        label="Go to Special Offers"
      >
        <div className="panel__account--arrowContainer">{offerTemplate}</div>
      </AccountLink>
    );
  };

  getPromoCardTemplate = (GIFT_CARD_NOTIFICATIONS, goToPage) => {
    const {
      promoCards,
      promoCardNotificationCount,
      hasPromoCardExpiringOffer,
    } = this.state;
    const promoCardTemplate = GIFT_CARD_NOTIFICATIONS
      ? determinePromocardTemplate(
        promoCardNotificationCount,
        promoCards,
        hasPromoCardExpiringOffer
      )
      : `Promotional Gift Cards -${promoCards || 'None'} Available`;
    return (
      <AccountLink handleClick={() => goToPage('promoPage')} label="Go to Promo page">
        <div className="panel__account--arrowContainer panel__account--promoCards">
          {promoCardTemplate}
          <button className="panel__account--arrowRight" />
        </div>
      </AccountLink>
    );
  };

  getIncircleCardTemplate = (goToPage) => {
    const {
      inCircleCards,
      incircleCardNotificationCount,
      hasIncircleCardExpiringOffer,
    } = this.state;
    const inCircleardTemplate = determineIncircleCardTemplate(
      incircleCardNotificationCount,
      inCircleCards,
      hasIncircleCardExpiringOffer
    );
    if (inCircleardTemplate) {
      return (
        <AccountLink handleClick={() => goToPage('inCirclePage')} label="Go to Incircle Page">
          <div className="panel__account--arrowContainer panel__account--incircle">
            {inCircleardTemplate}
            <button className="panel__account--arrowRight" />
          </div>
        </AccountLink>
      );
    }
    return getPrimaryIncircleDetails(goToPage);
  };

  render() {
    const { promoCards, inCircleCards, userIncircleMemberCheck } = this.state;
    return (
      <Consumer>
        {({
          name, isAuthenticated, goToPage, toggles = {},
        }) => (
          <PromiseLoader
            promise={() => this.getAccountData(
              toggles.PROMO_NOTIFICATIONS,
              toggles.GIFT_CARD_NOTIFICATIONS,
              toggles.NMO_LOYALTY_NEW
            )
            }
            defaultLoading
            loadingTemplate={this.getLoadingTemplate(toggles, name)}
          >
            <div className="panel__account" role="list">
              <span className="panel__account-title">Your Account</span>
              {name && isAuthenticated ? (
                <>
                  <AccountLink
                    handleClick={() => handleProtectedClick('/account/account.jsp')
                    }
                    label="Go to Account Overview"
                  >
                    Overview
                  </AccountLink>
                  <AccountLink
                    handleClick={() => handleProtectedClick('/order-history')}
                    label="Go to Order History"
                  >
                    Order History
                  </AccountLink>
                  {
                    toggles?.NMO_LOYALTY_NEW && (
                      userIncircleMemberCheck
                        ? (
                          <AccountLink
                            handleClick={() => {
                              goToPage('InCircleNmCreditCard');
                            }}
                            label="Go to NM Credit card"
                          >
                            <div className="panel__account--arrowContainer panel__account--incircle">
                              NM Credit Card
                              <button className="panel__account--arrowRight" />
                            </div>
                          </AccountLink>
                        )
                        : (
                          <AccountLink
                            handleClick={() => {
                              window.location.href = '/my/NMCreditCard';
                            }}

                            label="Go to NM Credit card"
                          >
                            NM Credit Card
                          </AccountLink>
                        )
                    )
                  }

                  {!toggles.NMO_LOYALTY_NEW && toggles.PANEL_OFFERS
                    && this.getOffersTemplate(toggles.PROMO_NOTIFICATIONS)}
                  {!!(toggles.PROMOGC_PANEL && !toggles.NMO_LOYALTY_NEW && promoCards)
                    && this.getPromoCardTemplate(
                      toggles.GIFT_CARD_NOTIFICATIONS,
                      goToPage
                    )}
                  {toggles.INCIRCLE_PANEL_PAGE && !toggles.NMO_LOYALTY_NEW && (
                    <>
                      {toggles.INCIRCLE_NOTIFICATIONS && inCircleCards
                        ? this.getIncircleCardTemplate(goToPage)
                        : getPrimaryIncircleDetails(goToPage)}
                    </>
                  )}

                  {toggles.MY_STYLE_PREFERENCES && (
                    <AccountLink
                      handleClick={(e) => {
                        e.stopPropagation();
                        window.location.href = '/my/preferences/stylepreferences';
                      }}
                      label="Go to Style Preferences"
                    >
                      My Style Preferences
                    </AccountLink>
                  )}
                  {toggles.NMO_LOYALTY_NEW && toggles.LOY_LANDING && (
                    <AccountLink
                      handleClick={(e) => {
                        e.stopPropagation();
                        window.location.href = '/my/Loyalty';
                      }}
                      label="Go to Loyalty Hub Page"
                    >
                      Loyalty
                    </AccountLink>
                  )}
                </>
              ) : (
                <>
                  <AccountLink
                    handleClick={() => goToPage('accountAccessPage')}
                    label="Go to Sign in"
                  >
                    Sign in / Register
                  </AccountLink>
                  <AccountLink
                    handleClick={(e) => {
                      e.stopPropagation();
                      window.location.href = '/guest-order-history';
                    }}
                    label="Go to Order History"
                  >
                    Order History
                  </AccountLink>
                  {
                    toggles?.NMO_LOYALTY_NEW && (
                      <AccountLink
                        handleClick={(e) => {
                          e.stopPropagation();
                          window.location.href = '/my/Loyalty';
                        }}
                        label="Go to NM Credit card"
                      >
                        NM Credit Card
                      </AccountLink>
                    )
                  }

                  {toggles.MY_STYLE_PREFERENCES && (
                    <AccountLink
                      handleClick={(e) => {
                        e.stopPropagation();
                        window.location.href = '/login?navpath=stylePref';
                      }}
                      label="Go to Style Preferences"
                    >
                      My Style Preferences
                    </AccountLink>
                  )}
                  {toggles.NMO_LOYALTY_NEW && toggles.LOY_LANDING && (
                    <AccountLink
                      handleClick={(e) => {
                        e.stopPropagation();
                        window.location.href = '/my/Loyalty';
                      }}
                      label="Go to Loyalty Hub Page"
                    >
                      Loyalty
                    </AccountLink>
                  )}
                </>
              )}
              {
                !toggles?.NMO_LOYALTY_NEW && (
                  <AccountLink
                    handleClick={(e) => {
                      e.stopPropagation();
                      window.location.href = '/services/NMCreditCard';
                    }}
                    label="Go to NM Credit card"
                  >
                    NM Credit Card
                  </AccountLink>
                )
              }
            </div>
          </PromiseLoader>
        )}
      </Consumer>
    );
  }
}

export default Account;
