import React from 'react';

export default class PromiseLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !!props.defaultLoading,
    };
  }

  componentDidMount() {
    const fallback = (arg) => arg;
    const {
      defaultLoading, promise, errCB = fallback, cb = fallback,
    } = this.props;
    if (defaultLoading) {
      promise()
        .then(cb)
        .catch(errCB)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { children, loadingTemplate } = this.props;
    const { loading } = this.state;

    if (loading) {
      return loadingTemplate;
    }

    return children;
  }
}
