import React, { useState } from 'react';
import ModalButtons from './ModalButtons';
import { getFormattedPhoneNumber, requestConfirmationCode } from './utilities';

const RemovePhoneNumber = ({
  increaseModalStep,
  userAttributes = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const closePanel = () => window?.YourNeimans.setData({ isOpen: false });

  const onInitRemovePhoneSuccess = () => {
    setLoading(false);
    increaseModalStep();
  };

  const onInitPhoneRemovalFailure = () => {
    setLoading(false);
    setError('Unable to remove phone number, please check back again later');
  };

  const onPhoneRemoval = () => {
    setLoading(true);
    setError(null);

    requestConfirmationCode(
      onInitRemovePhoneSuccess,
      onInitPhoneRemovalFailure
    );
  };

  return (
    <>
      <div className="infoLine">
        <span>
        By removing your phone number
          {' '}
          <strong>{getFormattedPhoneNumber(userAttributes?.phone_number)}</strong>
, you are disabling alerts for suspicious activity.&nbsp;
        </span>
      </div>

      <div className="mfaError">{error}</div>
      <ModalButtons
        closeButtonTitle="Cancel"
        successButtonTitle="Remove"
        onCloseButtonClick={closePanel}
        onSuccessButtonClick={onPhoneRemoval}
        disableSuccessButton={loading}
      />
    </>
  );
};

export default RemovePhoneNumber;
