import React from 'react';

const ModalButtons = ({
  closeButtonTitle, successButtonTitle, onCloseButtonClick,
  onSuccessButtonClick, disableSuccessButton, hideCloseButton,
}) => (

  <div className="mfaButtonWrapper">
    {!hideCloseButton && (
    <button
      className="mfaCloseButton"
      onClick={onCloseButtonClick}
    >
      {closeButtonTitle}
    </button>
    )}
    <button
      className={!disableSuccessButton ? 'mfaSuccessButton' : 'mfaDisabledButton'}
      onClick={onSuccessButtonClick}
      disabled={disableSuccessButton}
    >
      {successButtonTitle}
    </button>
  </div>
);

export default ModalButtons;
