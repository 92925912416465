import React from 'react';
import styleAdvisor from 'assets/images/style_advisor.svg';
import { logout } from '../../../../../utilities/amplifyUtils';
import { Consumer } from '../../PanelContext';
import { shortenName } from '../utilities';
import './Header.scss';

const Header = ({ showBanner, showBack = true }) => (
  <Consumer>
    {({
      togglePanel,
      goBack,
      name,
      isAuthenticated,
      pageStack,
      toggles: {
        IMPROVED_PANEL_CTA,
        DISABLE_ATG_LOGIN,
      },
    }) => (
      <div className="panel__header">
        {showBanner && (
          <span className="panel__header-title">
            <img
              className="panel__header-icon"
              alt="Your Neiman's"
              aria-hidden="true"
              src={styleAdvisor}
            />
            {name && (IMPROVED_PANEL_CTA ? isAuthenticated : true) ? (
              <h1>
                <a aria-label="Account Overview" href="/account/account.jsp">
                  {'Welcome, '}
                  <span>{shortenName(name)}</span>
                </a>
                {(IMPROVED_PANEL_CTA ? true : isAuthenticated) && <span className="panel__header-signout" onClick={() => logout(DISABLE_ATG_LOGIN)}>Sign Out</span>}
              </h1>
            ) : (
              <h1>Welcome</h1>
            )}
          </span>
        )}
        {showBack && pageStack.length > 1 && (
          <button
            aria-label="Back"
            className="panel__header-back"
            onClick={goBack}
          />
        )}
        <button
          id="panelHeader"
          aria-label="Close Panel"
          className="panel__header-close"
          onClick={togglePanel}
          tabIndex={0}
        />
      </div>
    )}
  </Consumer>
);

export default Header;
