import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import SavePhoneNumber from './SavePhoneNumber';
import VerifySMSCode from './VerifySMSCode';
import NeedHelp from './NeedHelp';
import RemovePhoneNumber from './RemovePhoneNumber';
import VerifyEmailCode from './VerifyEmailCode';
import { getBasicProfileInfo } from '../../../utilities/mfaActionUtils';
import { mfaFlows } from './constants';
import './MFAComponentStyles.scss';

const pages = {
  [mfaFlows.optIn]: [
    {
      component: SavePhoneNumber,
      title: 'Verify Your Phone Number',
      infoText: 'Receive a one-time code sent to the number below and enable future text alerts for suspicious activity.',
    },
    {
      component: VerifySMSCode,
      title: 'Verify Your Phone Number',
    },
  ],
  [mfaFlows.optOut]: [
    {
      component: RemovePhoneNumber,
      title: 'Are you sure?',
    },
    {
      component: VerifyEmailCode,
      title: 'Let\'s Confirm it\'s you',
    },
  ],
  [mfaFlows.signIn]: [
    {
      component: VerifySMSCode,
      title: 'Let\'s Confirm it\'s you',
    },
  ],
  [mfaFlows.needHelp]: [{ component: NeedHelp, title: 'Need Help?' }],
};

const MFAPanelComponent = ({
  UCA_PROFILE_COOKIE,
  EMPLOYEE_STATUS_V2,
  DISCOUNT_ELIGIBILITY_V1,
  PZP_IDENTITY,
  DISABLE_ATG_LOGIN,
  user,
  updateNameHeaders,
  removemfa,
  ...props
}) => {
  const [modalStep, setModalStep] = useState(0);
  const [mfaContext, setMfaContext] = useState({});
  const [userAttributes, setUserAttributes] = useState({});

  const setData = (userAttributes) => {
    // If user attributes are present it always can be only Opt In flow.
    if (userAttributes && userAttributes != null) {
      setUserAttributes({ ...userAttributes });
      setMfaContext({ flow: (removemfa ? mfaFlows.optOut : mfaFlows.optIn) });

      return;
    }

    if (user) {
      // If user attributes are missed, it can be only sign in flow
      let email = null;
      if (user?.extra) {
        email = user?.extra?.email;
      }
      if (!email) {
        const emailRegExp = new RegExp(`${user?.keyPrefix || ' '}.|.userData`, 'g');
        email = user?.userDataKey && user?.keyPrefix ? user.userDataKey?.replace(emailRegExp, '') : '';
      }
      const smsSentPhone = user?.challengeParam?.CODE_DELIVERY_DESTINATION || '';

      setUserAttributes({ ...userAttributes, phone_number: smsSentPhone, email });
    }

    if (user?.challengeName === 'SMS_MFA') {
      setMfaContext({ flow: mfaFlows.signIn });
    }
  };

  useEffect(() => {
    getBasicProfileInfo(setData);
  }, []);

  const resetStep = useCallback(() => setModalStep(0), []);

  const increaseModalStep = useCallback(() => {
    setModalStep((currentStep) => currentStep + 1);
  }, []);

  const goToHomepage = useCallback(() => {
    resetStep();
    props.goToPage('homePage');
  }, [props.goToPage, resetStep]);

  const goToLoginPage = useCallback(() => {
    resetStep();
    props.goToPage('accountAccessPage');
  }, [props.goToPage, resetStep]);

  const navigateToHelpPage = useCallback(() => {
    setMfaContext({ flow: mfaFlows.needHelp });
    setModalStep(0);
  }, []);

  const updateMfaFlow = useCallback((mfaContextFlow, step = 0) => {
    setMfaContext({ flow: mfaContextFlow });
    setModalStep(step);
  }, []);

  const toggleMFAModal = () => {
    resetStep();
    props.goToPage('homePage');
  };

  const Component = useMemo(() => (
    mfaContext?.flow ? pages[mfaContext.flow][modalStep].component : <></>),
  [modalStep, mfaContext.flow]);

  const pageTitle = useMemo(() => pages[mfaContext?.flow]?.[modalStep].title || 'Sorry something went wrong',
    [mfaContext.flow, modalStep]);

  const pageInfoText = useMemo(() => pages[mfaContext?.flow]?.[modalStep].infoText || null,
    [mfaContext.flow, modalStep]);

  if (!mfaContext.flow) {
    return null;
  }

  return (
    <div>
      <div className="panel__mfa">
        <span className="mfaModalTitle">{pageTitle}</span>

        <Component
          isPanel
          increaseModalStep={increaseModalStep}
          closeModal={goToHomepage}
          goToLoginPage={goToLoginPage}
          toggleMFAModal={toggleMFAModal}
          userAttributes={userAttributes}
          updateUserAttributes={setUserAttributes}
          mfaContext={mfaContext}
          infoText={pageInfoText}
          navigateToHelpPage={navigateToHelpPage}
          toggles={{
            DISABLE_ATG_LOGIN,
            UCA_PROFILE_COOKIE,
            PZP_IDENTITY,
            EMPLOYEE_STATUS_V2,
            DISCOUNT_ELIGIBILITY_V1,
          }}
          user={user}
          updateNameHeaders={updateNameHeaders}
          updateMfaFlow={updateMfaFlow}
        />
      </div>
    </div>
  );
};

export default MFAPanelComponent;
