import React, { Component } from 'react';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { v4 } from 'uuid';
import classNames from 'classnames';
import ArrowLeft from 'assets/images/arrow-left.svg';
import ArrowRight from 'assets/images/arrow-right.svg';
import IncircleLogo from 'assets/images/incircle-logo.svg';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { normalizeString } from '../../../../../profile/components/OrderHistoryDT/OrderHistoryUtils';
import InCircleMeterComponent from './InCircleMeter/InCircleMeter';
import cardBackgroundPerk from '../../../../../assets/images/card-background-perk.svg';
import cardBackground from '../../../../../assets/images/card-background.svg';
import { CIRCLE_REWARDS, CIRCLE_NAMES } from './constants';
import { formatCardData } from '../utilities';
import './InCirclePage.scss';


const CLASS_PREFIX = 'incircle_page';

export class InCirclePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointData: {
        Earned: '',
        Redeemed: '',
        Available: '',
      },
      cardData: [],
      circleLevel: 0,
      numAccounts: 1,
      currAccount: 1,
      currTenderId: -1,
      incircleData: [{
        benefitLevelName: '',
        points: 0,
        Redeemed: 0,
        Available: 0,
        Earned: 0,
        asOfDate: '',
        benefitLevel: 0,
        pointsYear: '',
      }],
      currCard: 1,
      currKI: '',
    };
  }

  componentDidMount() {
    return Auth.currentAuthenticatedUser({})
      .then((user) => {
        const {
          INCIRCLE_NOTIFICATIONS,
        } = this.props;
        const mappedIncircleData = this.mapIncircleData(JSON.parse(sessionStorage.getItem('incircleData')));
        this.setState(mappedIncircleData);
        if (this.props.GIFTCARDS_PANEL) {
          const lambdaHost = window.sessionStorage.getItem('lambdaHost');
          const fastlyHost = window.sessionStorage.getItem('fastlyUCAHost') || lambdaHost;
          const profileId = user.attributes.preferred_username;
          const jwtToken = user.signInUserSession.idToken.jwtToken;
          const baseURL = `${fastlyHost}/customer-data/v1/${profileId}`;
          const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'X-NMG-CARD-CLASS': 'ALL',
          };
          axios.get(`${baseURL}/giv-cards`, { headers }).then(({ data }) => {
            if (INCIRCLE_NOTIFICATIONS) {
              saveToLocalStorage(`incircleData.${profileId}`, JSON.stringify(data));
            }
            const sortedData = data.sort(
              (a, b) => new Date(a.expire_date) - new Date(b.expire_date)
               || parseInt(a.last_ptd_balance, 10) - parseInt(b.last_ptd_balance, 10)
            );
            const perksAndPointsData = sortedData.filter((card) => (card.type === 'point' || card.type === 'perk'));

            this.setState({ cardData: perksAndPointsData });
          });
        }
      })
      .catch((err) => err);
  }

  mapIncircleData = (incircleData) => {
    const mappedData = incircleData.map((data) => {
      const levelKey = +data.benefit_level > +data.earned_level ? 'benefit_level' : 'earned_level';
      const benefitLevel = +data[levelKey] || -1;
      return {
        benefitLevelName: normalizeString(data[`${levelKey}_name`]?.trim()),
        points: data.current_points,
        Redeemed: data.redeemed,
        Available: data.redeem_avail,
        Earned: data.redeem_earned,
        asOfDate: data.redeem_asof_date,
        tenderId: data.tenderId.substring(data.tenderId.lastIndexOf('*') + 1),
        benefitLevel,
        pointsYear: data.redeem_program_ccyy,
      };
    });
    return {
      numAccounts: incircleData.length,
      currKI: mappedData[0].tenderId,
      incircleData: mappedData,
    };
  }

  renderPointData = (pointData) => {
    return Object.keys(pointData).map((type) => {
      const amount = pointData[type];
      return (
        <div className={`${CLASS_PREFIX}__point-data`} key={v4()}>
          <h1 className={`${CLASS_PREFIX}__point-data--amount`}>{amount}</h1>
          <h2 className={`${CLASS_PREFIX}__point-data--type`}>{type}</h2>
        </div>
      );
    });
  }

  renderBackgroundImage = (type) => {
    if (type === 'point') {
      return { image: cardBackground, colorClass: `${CLASS_PREFIX}__card--points` };
    }
    return { image: cardBackgroundPerk, colorClass: `${CLASS_PREFIX}__card--perks` };
  }

  switchAccounts = (count, isCard) => {
    const {
      currAccount, numAccounts, cardData, currCard, incircleData,
    } = this.state;
    const makeMap = (currAcc, numAcc, key) => ({ currAcc, numAcc, key });

    const { currAcc, numAcc, key } = isCard
      ? makeMap(currCard, cardData.length, 'currCard')
      : makeMap(currAccount, numAccounts, 'currAccount');

    let newAccount = currAcc + count;
    if (newAccount <= 0) newAccount = numAcc;
    else if (newAccount > numAcc) newAccount = 1;

    if (!isCard) {
      this.setState({ currCard: 1, currKI: incircleData[newAccount - 1].tenderId });
    }

    this.setState({ [key]: newAccount });
  }

  renderCardData = () => {
    const { currCard, cardData = [] } = this.state;
    const {
      accountNumber,
      expire_date: expireDate,
      last_ptd_balance: lastPtdBalance,
      type,
    } = cardData?.[currCard - 1] || {};

    const { formattedNumber, formattedDate } = formatCardData(accountNumber, expireDate);

    if (!expireDate) return null;

    return (
      <div className={`${CLASS_PREFIX}__card--container`}>
        <p className={`${CLASS_PREFIX}__card--heading`}>Your Rewards Cards</p>
        <h5 className={`${CLASS_PREFIX}__card-header--accounts`}>
          <img
            className={`${CLASS_PREFIX}__card--arrowLeft`}
            src={ArrowLeft}
            alt="left_account_arrow"
            onClick={() => this.switchAccounts(-1, true)}
          />
          Card
          {' '}
          {currCard}
          {' '}
of
          {' '}
          {cardData.length}
          <img
            className={`${CLASS_PREFIX}__card--arrowRight`}
            src={ArrowRight}
            alt="right_account_arrow"
            onClick={() => this.switchAccounts(1, true)}
          />
        </h5>
        <div className={`${CLASS_PREFIX}__card`}>
          <img src={(this.renderBackgroundImage(type)).image} alt="card_background" />
          <div className={`${CLASS_PREFIX}__card--picture`}>
            <h5 className={(this.renderBackgroundImage(type)).colorClass}>{formattedNumber}</h5>
            <h5 className={(this.renderBackgroundImage(type)).colorClass}>
CURRENT BALANCE: $
              {parseFloat(lastPtdBalance).toFixed(2)}
            </h5>
            <h5 className={(this.renderBackgroundImage(type)).colorClass}>
EXPIRES
              {' '}
              {formattedDate}
            </h5>
          </div>
        </div>
        <h6 className={`${CLASS_PREFIX}__card--disclaimer`}>Current balance may not reflect activity within last 24 hours. Proceed to checkout to check balance and apply to your online order.</h6>
      </div>
    );
  }

  render() {
    const {
      GIFTCARDS_PANEL,
      SHOW_INCIRCLE_YEAR,
    } = this.props;
    const {
      currAccount,
      numAccounts,
      incircleData,
      cardData,
    } = this.state;
    const {
      benefitLevelName,
      points,
      Redeemed,
      Available,
      Earned,
      benefitLevel,
      pointsYear,
    } = incircleData[currAccount - 1] || {};
    const rewards = CIRCLE_REWARDS[benefitLevel + 1] || [];
    const wrappedPoints = points % 10000;
    return (
      <div className={classNames(
        `${CLASS_PREFIX}__container`,
        !GIFTCARDS_PANEL ? `${CLASS_PREFIX}__container--giftDisabled` : ''
      )}
      >
        <img src={IncircleLogo} alt="Incircle_Logo" className={`${CLASS_PREFIX}__logo`} />
        <h5 className={`${CLASS_PREFIX}__accounts`}>
          <img
            className={`${CLASS_PREFIX}__arrow--left`}
            src={ArrowLeft}
            alt="left_account_arrow"
            onClick={() => this.switchAccounts(-1)}
          />
          Account
          {' '}
          {currAccount}
          {' '}
of
          {' '}
          {numAccounts}
          <img
            className={`${CLASS_PREFIX}__arrow--right`}
            src={ArrowRight}
            alt="right_account_arrow"
            onClick={() => this.switchAccounts(1)}
          />
        </h5>
        {pointsYear && SHOW_INCIRCLE_YEAR && (
        <h4>
Year:&nbsp;
          {pointsYear}
        </h4>
        )}
        <h1 className={`${CLASS_PREFIX}__circle`}>{benefitLevelName}</h1>
        <InCircleMeterComponent percentage={wrappedPoints / 100}>
          <h1 className={`${CLASS_PREFIX}__circle`}>{10000 - wrappedPoints}</h1>
          <h4 className={`${CLASS_PREFIX}__text`}>Points</h4>
        </InCircleMeterComponent>
        <h4 className={`${CLASS_PREFIX}__circle`}>to next point card</h4>
        <div className={`${CLASS_PREFIX}__point-data--container`}>
          {this.renderPointData({ Earned, Redeemed, Available })}
        </div>
        <div className={`${CLASS_PREFIX}__card-data--container`}>
          {GIFTCARDS_PANEL && !!Object.keys(cardData).length && this.renderCardData()}
        </div>
        <a
          href="https://www.incircle.com/account/login.jsp?navid=loginmainc"
          className={`${CLASS_PREFIX}__manage`}
          rel="noopener noreferrer"
          target="_blank"
        >
          See More Information
        </a>
        <h2 className={`${CLASS_PREFIX}__insider`}>Insider Tip!</h2>
        <p className={`${CLASS_PREFIX}__bonus`}>Watch for bonus-point events to get more points.</p>
        {!!rewards.length && (
        <h2 className={`${CLASS_PREFIX}__reach`}>
Reach
          {' '}
          {(benefitLevel > -1 && benefitLevel < 6) && 'Circle '}
          {CIRCLE_NAMES[benefitLevel + 1]}
          {' '}
and get:
        </h2>
        )}
        {rewards.map((reward) => (
          <p className={`${CLASS_PREFIX}__bonus`} key={v4()}>{reward}</p>
        ))}
        <a
          href="https://www.incircle.com/category.jsp?itemId=cat103411&parentId=&siloId=cat103411&navid=topNavMemberBenefits"
          className={`${CLASS_PREFIX}__view-benefits`}
          rel="noopener noreferrer"
          target="_blank"
        >
          View All Level Benefits
        </a>
      </div>
    );
  }
}

export default InCirclePage;
