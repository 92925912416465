import axios from 'axios';
import { PHONE_MATCH_REGEXP, REMOVE_PHONE_NUMBER, CONFIRM_REMOVE_PHONE_NUMBER } from './constants';
import { getAuthApiData } from '../../../utilities/amplifyUtils';

/**
 * Helper function generate one time verification code for remove phone number
 * @param {function} onSuccess - Success callback function
 * @param {function} onError - error callback function
 */
export const requestConfirmationCode = async (onSuccess = () => {}, onError = () => {}) => {
  const requestConfigData = await getAuthApiData();

  if (!requestConfigData) return;

  try {
    const { fastlyHost, ucaId, headers } = requestConfigData;

    const { message } = await axios.post(
      `${fastlyHost}${REMOVE_PHONE_NUMBER.replace('{ucaId}', ucaId)}`,
      {},
      { headers }
    );
    onSuccess(message);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    onError();
  }
};

/**
 * Helper fuction to submit remove phone number verification code
 * @param {string} code - Verification code received on email
 * @param {function} onSuccess - Success callback function
 * @param {function} onError - error callback function
 */
export const confirmPhoneRemoval = async (code, onSuccess = () => {}, onError = () => {}) => {
  const requestConfigData = await getAuthApiData();

  if (!requestConfigData) return;

  try {
    const {
      fastlyHost, ucaId, headers, user,
    } = requestConfigData;

    const { message } = await axios.post(
      `${fastlyHost}${CONFIRM_REMOVE_PHONE_NUMBER.replace('{ucaId}', ucaId)}`,
      { code },
      { headers },
    );

    user.getUserData(() => onSuccess(message), { bypassCache: true });
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    onError();
  }
};


export const getFormattedPhoneNumber = (phone) => {
  if (!phone) {
    return '';
  }

  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(PHONE_MATCH_REGEXP);

  return match ? ['(', match[2], ') ', match[3], '-', match[4]].join('') : phone;
};

export const sendUtagLinkData = (addlProps) => new Promise((resolve) => {
  window?.utag?.link?.({ ...window.utag_data_dt, ...addlProps }, resolve);
});

export const mfaLoginSuccessUtagData = (payload) => {
  const { kmsiFlag, customerEmail } = payload;
  const mfaSucces = {
    customer_email: customerEmail,
    login_bt_click: 'true',
    logged_in_previous_page_flag: 'true',
    logged_in_status: 'true',
    kmsi_flag: kmsiFlag.toString(),
  };
  sendUtagLinkData(mfaSucces);
};
export const mfaVerifyPanelUtagData = () => {
  const panelData = {
    customer_email: undefined,
    login_bt_click: undefined,
    logged_in_previous_page_flag: undefined,
    logged_in_status: undefined,
    event_name: 'mfa display',
    kmsi_flag: undefined,
  };
  sendUtagLinkData(panelData);
};

export const mfaLoginFailureUtagData = () => {
  const mfaFailure = {
    customer_email: undefined,
    login_bt_click: 'true',
    logged_in_previous_page_flag: 'false',
    kmsi_flag: undefined,
  };
  sendUtagLinkData(mfaFailure);
};
