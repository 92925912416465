import React from 'react';

const AccountLink = ({ handleClick, children, label }) => {
  return (
    <span className="panel__account-link" role="button">
      <button
        className="panel__protected-links"
        onClick={handleClick}
        aria-label={label}
      >
        {children}
      </button>
    </span>
  );
};

export default AccountLink;
