import React, { useState, useEffect } from 'react';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import cardBackground from '../../../../../assets/images/nm-card-back.svg';
import { getAuthApiData } from '../../../../../utilities/amplifyUtils';
import { fetchPromoCardData, formatCardData } from '../utilities';
import './Promo.scss';

export const handlePromoMount = (setPromoCards, GIFT_CARD_NOTIFICATIONS) => {
  const cachedCardData = sessionStorage.getItem('promoCards');
  if (cachedCardData && !GIFT_CARD_NOTIFICATIONS) {
    setPromoCards(JSON.parse(cachedCardData).sort(
      (a, b) => new Date(a.expire_date) - new Date(b.expire_date)
    ));
    return Promise.resolve();
  }
  return getAuthApiData()
    .then(({ fastlyHost, profileId, headers }) => {
      fetchPromoCardData(fastlyHost, profileId, headers)
        .then(({ data }) => {
            window?.sessionStorage.setItem('promoCards', JSON.stringify(data));
            if (GIFT_CARD_NOTIFICATIONS) {
              saveToLocalStorage(`${profileId}.lastViewedPromoCards`, JSON.stringify(data));
            }
            setPromoCards(data);
        });
    });
};

export const Promo = ({ GIFT_CARD_NOTIFICATIONS }) => {
  const [promoCards, setPromoCards] = useState([]);

  useEffect(() => {
    handlePromoMount(setPromoCards, GIFT_CARD_NOTIFICATIONS);
  }, []);

  return (
    <div className="panel_promo__container">
      <h1>Your Promotional Gift Cards</h1>
      <h5>
Current balance may not reflect activity within last 24 hours. Proceed to
        checkout to check balance and apply to your online order.
      </h5>
      {promoCards.map(({
        accountNumber,
        expire_date: expireDate,
        last_ptd_balance: lastPtdBalance,
      }, i) => {
        const { formattedNumber, formattedDate } = formatCardData(accountNumber, expireDate);
        return (
          <div className="panel_promo__card-container" key={i}>
            <img src={cardBackground} alt="card_background" />
            <div className="panel_promo__card-content">
              <h5>{formattedNumber}</h5>
              <h5>
CURRENT BALANCE: $
                {lastPtdBalance}
              </h5>
              <h5>
EXPIRES:
                {formattedDate}
              </h5>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Promo;
