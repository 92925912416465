import React, { useState, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import PhoneInput from './PhoneInput';
import ModalButtons from './ModalButtons';
import {
  updateProfilePhoneNumber,
  updateOptInExpiration,
  removeOptInExpirationFromStorage,
} from '../../../utilities/mfaActionUtils';
import { getFormattedPhoneNumber } from './utilities';
import { TERMS_N_COND } from './constants';


const INVALID_CODE_ERROR_MESSAGE = 'Please provide a valid phone number';
const ENTER_PHONE_NUMBER_ERROR_MESSAGE = 'Please provide a valid phone number';

const SavePhoneNumber = ({
  increaseModalStep,
  userAttributes = {},
  updateUserAttributes,
  infoText,
  closeModal,
}) => {
  const [phoneNumber, setPhone] = useState(getFormattedPhoneNumber(userAttributes.phone_number));
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const disableSubmit = useMemo(() => isEmpty(phoneNumber) || !isPhoneValid,
    [phoneNumber, isPhoneValid]);

  const onPhoneValuesChange = ({ phone, phoneValid }) => {
    setPhone(phone);
    setPhoneValid(phoneValid);
    setSubmitted(false);
    if (phone === '') {
      setError(ENTER_PHONE_NUMBER_ERROR_MESSAGE);
    } else if (!phoneValid) {
      setError(INVALID_CODE_ERROR_MESSAGE);
    } else {
      setError(null);
    }
  };

  const onUpdatePhoneSuccess = (formattedPhoneNumber) => () => {
    removeOptInExpirationFromStorage(userAttributes);
    setLoading(false);
    updateUserAttributes({ ...userAttributes, phone_number: formattedPhoneNumber });
    increaseModalStep();
  };

  const onUpdatePhoneFailure = () => {
    setLoading(false);
    setError('Unable to update phone number, please check back again later');
  };

  const onClickSuccessButton = () => {
    setSubmitted(true);
    setLoading(true);

    if (!isPhoneValid) {
      setError(INVALID_CODE_ERROR_MESSAGE);
      return;
    }

    const formattedPhoneNumber = `+1${phoneNumber.replace(/\D/g, '')}`;

    updateProfilePhoneNumber(
      formattedPhoneNumber,
      onUpdatePhoneSuccess(formattedPhoneNumber),
      onUpdatePhoneFailure
    );
  };

  const onClickNotNow = () => {
    updateOptInExpiration(userAttributes);
    closeModal();
    window?.sessionStorage?.removeItem('verify_phone_mfa');
  };

  useEffect(() => {
    if (userAttributes.phone_number) {
      setPhoneValid(true);
    }
  }, [userAttributes.phone_number]);

  return (
    <>
      <div className="infoLine">{infoText}</div>

      <PhoneInput
        onPhoneValuesChange={onPhoneValuesChange}
        submitted={submitted}
        phoneNumber={phoneNumber}
        hasError={error && true}
      />

      {error && <div className="mfaError">{error}</div>}

      <div className="infoLine">
        <span className="mfa-bottom-text">
        Message and data rates may apply.
          {' '}
          <a className="mfa-learn-more" href={TERMS_N_COND}>See Terms & Conditions</a>
        </span>
      </div>

      <ModalButtons
        closeButtonTitle="Cancel"
        successButtonTitle="Send Code"
        onCloseButtonClick={onClickNotNow}
        onSuccessButtonClick={onClickSuccessButton}
        disableSuccessButton={disableSubmit || loading}
      />
    </>
  );
};

export default SavePhoneNumber;
