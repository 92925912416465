import React, { Component } from 'react';
import { OUR_STORES } from 'client/common/constants';
import Store from './Store';
import { Consumer } from '../../PanelContext';
import {
  sortStoreData,
  getStoreCookie,
  getStoreJson,
  ENTER_KEYCODE,
} from '../utilities';
import './StoreList.scss';

class StoreList extends Component {
  constructor(props) {
    super(props);
    const { storeSelected } = props;
    this.state = {
      locationSearch: '',
      initialStoreSelected: storeSelected,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyDownStoreSetUp = this.handleKeyDownStoreSetUp.bind(this);
    this.handleSetStoreClick = this.handleSetStoreClick.bind(this);
  }

  componentDidMount() {
    const {
      location, setStoreList, storeData, setHeaderLinkUtag,
    } = this.props;
    if (location && !storeData.length) {
      setStoreList(location);
    }

    this.initialStore();
    if (setHeaderLinkUtag) {
      setHeaderLinkUtag(OUR_STORES);
    }
  }

  componentDidUpdate(prevProps) {
    const { storeData, setLocation } = this.props;
    const { locationSearch } = this.state;
    if (prevProps.storeData.length !== storeData.length
      && storeData.length) {
      locationSearch.length && setLocation(locationSearch);
    }
  }

  initialStore() {
    const { storeData, setStoreSelected } = this.props;
    const storeCookie = getStoreCookie();
    if (storeCookie.id && storeCookie.id !== 'undefined') {
      const storeDataExists = storeData.filter(
        (store) => store.id === storeCookie.id,
      )[0];
      storeDataExists
        ? setStoreSelected(storeDataExists)
        : getStoreJson(storeCookie.number).then(({ data }) => {
          setStoreSelected(data);
          this.setState({ initialStoreSelected: data });
        });
    }
  }

  handleChange(e) {
    this.setState({ locationSearch: e.target.value });
  }

  handleKeyDown(e) {
    if (e.which === ENTER_KEYCODE) {
      e.stopPropagation();
      this.handleSearch();
    }
  }

  handleSetStoreClick(store) {
    const { setStoreSelected } = this.props;
    setStoreSelected(store, true);
    if (window.YNStoreCallback) {
      window.YNStoreCallback(store);
    } else if (window.store) {
      window.store.dispatch({ type: 'SELECT_YOUR_STORE', selectedStore: store });
    }
  }

  handleKeyDownStoreSetUp(e, store) {
    if (e.key === 'Enter' || e.key === 'Return') {
      e.stopPropagation();
      this.handleSetStoreClick(store);
      setTimeout(() => {
        const focusElement = document.getElementById('panelHeader');
        focusElement && focusElement.focus();
      }, 20);
    }
  }

  handleSearch() {
    const { setStoreList, location, storeStatus } = this.props;
    const { locationSearch } = this.state;
    if (storeStatus === 'failed'
      || locationSearch !== location
      || locationSearch === ''
    ) {
      setStoreList(locationSearch);
      this.setState({ initialStoreSelected: undefined });
    }
  }

  renderStores() {
    const { initialStoreSelected } = this.state;
    const {
      storeSelected, storeData, NEW_STORES_UI,
    } = this.props;
    const sortedStoreData = sortStoreData(storeData, initialStoreSelected);

    return sortedStoreData.map((store, i) => {
      const currentStore = storeSelected
        ? store.id === storeSelected.id
        : false;
      return (
        <div key={i} className="panel__storeList-store">
          <Store store={store} details NEW_STORES_UI={NEW_STORES_UI} />
          <button
            disabled={!!currentStore}
            aria-label={
              currentStore
                ? `${store.name} Your Current Store`
                : `Set ${store.name} As Your Store`
            }
            className={
              currentStore
                ? 'panel__storeList-selected'
                : 'panel__storeList-set'
            }
            onClick={() => this.handleSetStoreClick(store)}
            onKeyDown={(e) => this.handleKeyDownStoreSetUp(e, store)}
          >
            {currentStore ? 'Your Current Store' : 'Set As Your Store'}
          </button>
        </div>
      );
    });
  }

  render() {
    const { locationSearch } = this.state;
    const { location, storeStatus, storeData } = this.props;
    const placeholder = location.length && storeData.length
      ? `Showing near ${location}`
      : 'Enter City, State or Zip';

    return (
      <div className="panel__storeList">
        <h1 className="panel__storeList-title">Select a Store</h1>
        <div className="panel__storeList-search">
          <input
            placeholder={placeholder}
            type="text"
            onFocus={(e) => {
              e.target.placeholder = 'Enter City, State or Zip';
            }}
            onBlur={(e) => {
              e.target.placeholder = placeholder;
            }}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            value={locationSearch}
          />
          <button onClick={this.handleSearch}>Search</button>
        </div>
        {storeStatus === 'resolved' && (
          this.renderStores()
        )}
        {storeStatus === 'failed' && (
          <span className="panel__storeList-error">
            We currently have no results for this search. Try changing the city, state, zip.
          </span>
        )}
        {storeStatus === 'loading' && (
          <span className="panel__storeList-loading">
            <div className="panel__spinner" />
          </span>
        )}
      </div>
    );
  }
}

const ConnectedStoreList = (props) => (
  <Consumer>
    {({
      location,
      setLocation,
      setStoreList,
      setStoreSelected,
      storeData,
      storeSelected,
      storeStatus,
      setHeaderLinkUtag,
    }) => (
      <StoreList
        {...props}
        location={location}
        setLocation={setLocation}
        setStoreList={setStoreList}
        setStoreSelected={setStoreSelected}
        storeData={storeData}
        storeSelected={storeSelected}
        storeStatus={storeStatus}
        setHeaderLinkUtag={setHeaderLinkUtag}
      />
    )}
  </Consumer>
);

export default ConnectedStoreList;
