import React, { useCallback, useState } from 'react';
import ModalButtons from './ModalButtons';
import { resetUserPhoneMFA } from '../../../utilities/mfaActionUtils';
import { mfaFlows, TERMS_N_COND } from './constants';

const NeedHelp = ({
  userAttributes, closeModal, updateMfaFlow, onCancel,
}) => {
  const email = userAttributes?.email || '';
  const [isLoading, setIsLoading] = useState(false);

  const failedResetPhone = useCallback(() => {
    setIsLoading(false);
  }, []);

  const successResetPhone = useCallback(() => {
    setIsLoading(false);
    closeModal();
  }, [closeModal]);

  const onSubmitResetMFA = useCallback(() => {
    setIsLoading(true);
    resetUserPhoneMFA(email, successResetPhone, failedResetPhone);
  }, []);

  const onClickCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
      return;
    }
    updateMfaFlow(mfaFlows.signIn);
  }, []);

  return (
    <>
      <div className="infoLine">
        If you’re having trouble signing in, please select “Send Email” below.
        {` We’ll send an email to ${email} with a link to continue verifying your account.`}
        <p className="tnc">
By continuing, you agree to our
          <a className="mfa-learn-more" href={TERMS_N_COND} target="_blank" rel="noopener noreferrer">&nbsp;Privacy Policy and Terms & Conditions</a>
.
        </p>
      </div>
      <ModalButtons
        closeButtonTitle="Cancel"
        successButtonTitle="Send Email"
        onSuccessButtonClick={onSubmitResetMFA}
        onCloseButtonClick={onClickCancel}
        disableSuccessButton={isLoading}
      />
    </>
  );
};

export default NeedHelp;
