import React from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './InCircleMeter.scss';

export const InCircleMeter = ({ percentage, children }) => {
  const trailStyles = {
    path: {
      stroke: '#d6d6d6',
      transform: 'rotate(225deg)',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: 'none',
    },
  };

  const pathStyles = {
    path: {
      stroke: 'black',
      transform: 'rotate(225deg)',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: 'none',
    },
  };

  return (
    <div className="incircle_meter__container">
      <div className="incircle_meter__trail">
        <CircularProgressbar
          value={75}
          styles={trailStyles}
        />
      </div>
      <div className="incircle_meter__path">
        <CircularProgressbarWithChildren
          value={0.75 * percentage}
          styles={pathStyles}
        >
          {children}
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default InCircleMeter;
