import React, { PureComponent } from 'react';
import { getEmail, sendUtagLinkData, validateEmail } from '../utilities';
import { Consumer } from '../../PanelContext';
import './Form.scss';

class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email || getEmail(),
      message: props.defaultMessage || '',
      errorEmail: '',
      errorMessage: '',
      submitStatus: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, message } = this.state;
    const {
      event,
      goToPage,
      saSelected,
      submit,
    } = this.props;
    const { email: saEmail, name: saName, associatePin: saPin } = saSelected;
    const validSubmission = this.validate();

    if (validSubmission) {
      this.setState({ submitStatus: 'loading' });
      submit(email, message, saEmail, saName, saPin).then((response) => {
        const { status } = response;
        if (status === 200) {
          goToPage('confirmationPage', { email });
          sendUtagLinkData({ event_name: event });
          this.setState({ submitStatus: 'resolved' });
        } else {
          this.setState({ submitStatus: 'failed' });
        }
      }).catch(() => {
        this.setState({ submitStatus: 'failed' });
      });
    }
  }

  validate() {
    const { optional } = this.props;
    const { email, message } = this.state;
    const validEmail = validateEmail(email);
    const validMessage = optional ? true : message.length > 0;

    this.setState({
      errorEmail: !validEmail && 'Enter a valid email address',
      errorMessage: !validMessage && 'Enter a valid message',
    });

    return validEmail && validMessage;
  }

  render() {
    const {
      errorApi,
      errorEmail,
      errorMessage,
      email,
      message,
      submitStatus,
    } = this.state;
    const {
      title,
      cta,
      optional,
      saSelected,
    } = this.props;

    return (
      <div className="panel__form">
        <h1 className="panel__form-title">{title}</h1>
        <div className="panel__form-fields">
          <label>To</label>
          <span>{`${saSelected.name}, Your Style Advisor`}</span>
          <label>From</label>
          <input
            aria-label="From Required"
            name="email"
            type="text"
            value={email}
            onChange={this.handleChange}
          />
          <span aria-live="assertive" className="panel__form-error">
            {errorEmail}
          </span>
          <label>
            Message
            {optional && (
              <span className="panel__form-optional"> (Optional)</span>
            )}
          </label>
          <textarea
            aria-label={optional ? 'Message Optional' : 'Message Required'}
            name="message"
            maxLength="150"
            placeholder="Enter text here"
            type="textarea"
            value={message}
            onChange={this.handleChange}
          />
          <span>{`${message.length}/150`}</span>
          <span aria-live="assertive" className="panel__form-error">
            {errorMessage}
          </span>
          <button
            className="panel__form-cta"
            onClick={(e) => this.handleSubmit(e)}
          >
            {cta}
          </button>
          <span aria-live="assertive" className="panel__form-error">
            {errorApi}
          </span>
          {submitStatus === 'loading' && (
            <div className="panel__spinner-overlay" />
          )}
        </div>
      </div>
    );
  }
}


const ConnectedForm = (props) => (
  <Consumer>
    {({
      email,
      goToPage,
      saSelected,
    }) => (
      <Form
        {...props}
        email={email}
        goToPage={goToPage}
        saSelected={saSelected}
      />
    )}
  </Consumer>
);


export default ConnectedForm;
