import React, { Component } from 'react';
import xmark from '../../assets/close.svg';
import checkmark from '../../assets/checkmark.svg';
import { getTimePrompt, capitalize } from '../utilities';
import './Store.scss';

const StoreHours = ({ hours }) => (
  <table aria-label="Store Hours">
    <tbody>
      {hours.map((currentDay) => {
        return currentDay.hours.map((hour, index) => {
          const day = index === 0 ? currentDay?.label : '';
          return (
            <tr key={day}>
              <td>{`${day}.`}</td>
              <td>{`- ${hour}`}</td>
            </tr>
          );
        });
      })}
    </tbody>
  </table>
);

const StoreServices = ({ services }) => {
  const servicesArray = [
    {
      name: services.onlineAptBooking.name,
      value: services.onlineAptBooking.isOpen,
    },
    {
      name: services.curbside.name,
      value: services.curbside.isOpen,
    },
    {
      name: services.inStoreShopping.name,
      value: services.inStoreShopping.isOpen,
    },
  ];

  return servicesArray.map(({ name, value }) => {
    return (
      <div key={name} className="panel__store-service">
        <img
          alt={value ? 'Available' : 'Not availble'}
          src={value ? checkmark : xmark}
        />
        {name}
      </div>
    );
  });
};

class Store extends Component {
  constructor(props) {
    super(props);
    const { expanded } = props;
    this.state = { toggleDetails: expanded || false };

    this.toggleStoreDetails = this.toggleStoreDetails.bind(this);
  }

  toggleStoreDetails() {
    const { toggleDetails } = this.state;
    this.setState({ toggleDetails: !toggleDetails });
  }

  render() {
    const { toggleDetails } = this.state;
    const {
      store,
      details,
      onClick,
      overview = true,
      NEW_STORES_UI,
    } = this.props;
    const {
      addressDetails,
      distance,
      name,
      phoneNumbers,
      storeServices,
      workingHours,
      storeNumber,
    } = store;
    const {
      addressLine1,
      city,
      postalCode,
      state,
    } = addressDetails[0];
    const cityLower = city?.toLowerCase().replace(/\s/g, '');
    const stateLower = state?.toLowerCase();
    const nameLower = name?.toLowerCase().replace(/\s/g, '');
    return (
      <div className="panel__store" onClick={onClick}>
        <span className="panel__store-name">
          {capitalize(name)}
          {distance && ` - (${distance} mi)`}
        </span>
        <span className="panel__store-time">
          {getTimePrompt(workingHours)}
        </span>
        {overview && (
          <StoreServices services={storeServices} />
        )}
        {details && (
          <>
            <button
              aria-expanded={toggleDetails}
              aria-label={`Store details for Neiman Marcus ${name}`}
              className={
                toggleDetails ? 'panel__store-expand' : 'panel__store-collapse'
              }
              onClick={this.toggleStoreDetails}
            >
              Store Details
            </button>
            <div
              className={
                toggleDetails
                  ? 'panel__store-details-visible'
                  : 'panel__store-details'
              }
            >
              <span className="panel__store-details-label">Address</span>
              <span>{addressLine1}</span>
              <span>{`${city}, ${state} ${postalCode}`}</span>
              <span className="panel__store-details-label">Phone Number</span>
              <span>{phoneNumbers[0]}</span>
              <span className="panel__store-details-label">Store Hours</span>
              <StoreHours hours={workingHours} />
              <div className="panel__store-details-link">
                <a
                  tabIndex={toggleDetails ? 0 : -1}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={NEW_STORES_UI ? `https://stores.neimanmarcus.com/stores/${(cityLower)}/${stateLower}/${nameLower}/${storeNumber}` : storeServices.curbside.links.altLink1.url}
                >
                  All Store Details
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Store;
