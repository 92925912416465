
import React, { Component } from 'react';
import './AccountAccess.scss';


export class DumbForgotPasswordModalDT extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      validEmail: true,
      submitted: false,
      submitSuccesful: false,
      error: false,
      disableContinue: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateEmailInput = this.validateEmailInput.bind(this);
  }

  componentDidMount() {
    const { email = '' } = this.props;
    this.setState({ email });
  }

  validateEmailInput = (email) => {
    if ((email.indexOf('@') === -1) || email.indexOf('.') === -1) {
      return false;
    } if (
      email.slice(0, (email.indexOf('@'))).length < 2
    ) {
      return false;
    }
    const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,10})+$/;
    return (email && regex.test(email));
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      validEmail: true,
    });
  }

  handleSubmit = () => {
    const { sendForgotPwEmail } = this.props;
    const email = this.state.email.toLowerCase();
    if (!this.validateEmailInput(email)) {
      this.setState({ validEmail: false });
    } else {
      this.setState({ disableContinue: true });
      sendForgotPwEmail(
        email,
        () => this.setState({ submitSuccesful: true, disableContinue: false }),
        () => this.setState({ error: true, disableContinue: false })
      );
    }
    this.setState({ submitted: true });
  }

  render() {
    const {
      email, submitted, validEmail, error, submitSuccesful, disableContinue,
    } = this.state;
    const { modalClassName } = this.props;

    const modalText = "Enter your email address and we'll send you an email with a link to reset your password. Be sure to check your spam folder.";
    const headerText = 'Forgot Your Password?';
    return (
      <div className="forgot-pw-flex-container">
        {!submitSuccesful
          ? (
            <div className={modalClassName}>
              <h2>{headerText}</h2>
              <p>
                {modalText}
              </p>
              <input
                id={`${(submitted && !validEmail) || (submitted && error) ? 'forgot-pw-error' : 'forgot-pw'}`}
                name="email"
                onChange={this.handleChange}
                value={email}
                placeholder="Email"
              />
              {submitted && !validEmail && <div className="forgot-pw-text-error">You must enter a valid email address.</div>}
              {submitted && error && validEmail && <div className="forgot-pw-text-error">Something went wrong, Please try again.</div>}
              <button
                id="forgot-pw-button"
                onClick={this.handleSubmit}
                disabled={disableContinue}
              >
                CONTINUE
              </button>
            </div>
          )
          : (
            <div className={modalClassName}>
              {' '}
              <h2>Check Your Email</h2>
              <p className="pw-confirmation">
                An email has been sent to
                {' '}
                {email}
                {' '}
                with instructions to reset your password.
                Note that it can take up to 5 minutes to receive your e-mail.
                For security purposes, we will remove the credit card information on your account.
              </p>
            </div>
          )}
      </div>
    );
  }
}

export default DumbForgotPasswordModalDT;
