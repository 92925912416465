import React, { Component } from 'react';
import Store from './Store';
import { Consumer } from '../../PanelContext';
import PromiseLoader from '../../hocs/PromiseLoader';
import { GeneralPanelSkeleton } from '../Skeleton/PanelSkeleton';
import { getStoreCookie, getStoreJson } from '../utilities';
import './StoreSelected.scss';

class StoreSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.getStorePromise = () => new Promise((resolve, reject) => {
      try {
        const { storeData, setStoreSelected } = props;
        const storeCookie = getStoreCookie();

        if (storeCookie.id) {
          this.setState({ loading: true });
          const storeDataExists = storeData.filter(
            (store) => store.id === storeCookie.id,
          )[0];

          if (storeDataExists) {
            setStoreSelected(storeDataExists);
            this.setState({ loading: false });
            resolve(storeDataExists);
          } else {
            getStoreJson(storeCookie.number).then(({ data }) => {
              this.setState({ loading: false });
              setStoreSelected(data);
              resolve(data);
            });
          }
        } else {
          resolve();
        }
      } catch (e) {
        this.setState({ loading: false });
        reject(e);
      }
    });
  }

  componentDidMount() {
    const { storeData, location, setStoreList } = this.props;
    if (location && !storeData.length) {
      setStoreList(location, true);
    }
  }

  getLoadingTemplate = () => (
    <div className="panel__storeSelected">
      <h2 className="panel__storeSelected-title">Your Store</h2>
      <GeneralPanelSkeleton />
    </div>
  )

  render() {
    const {
      storeSelected, goToPage, defaultLoading = true, NEW_STORES_UI,
    } = this.props;
    return (
      <PromiseLoader
        promise={this.getStorePromise}
        defaultLoading={defaultLoading}
        loadingTemplate={this.getLoadingTemplate()}
      >
        <div className="panel__storeSelected">
          <h2 className="panel__storeSelected-title">Your Store</h2>
          {storeSelected ? (
            <div>
              <button
                aria-label={`${storeSelected.name} Store Details`}
                onClick={(e) => {
                  e.stopPropagation();
                  goToPage('storeDetailsPage');
                }}
                className="panel__storeSelected-forward-float"
              />
              <Store
                store={storeSelected}
                NEW_STORES_UI={NEW_STORES_UI}
                onClick={() => goToPage('storeDetailsPage')}
              />
              <button
                aria-label="Change Your Store"
                id="changeLink"
                className="panel__storeSelected-change"
                onClick={(e) => {
                  e.stopPropagation();
                  goToPage('storeListPage');
                }}
              >
                Change
              </button>
            </div>
          ) : (
            <span
              className="panel__storeSelected-link"
              onClick={() => goToPage('storeListPage')}
            >
              Select a store
              <button
                aria-label="Select a store"
                className="panel__storeSelected-forward"
                onClick={(e) => {
                  e.stopPropagation();
                  goToPage('storeListPage');
                }}
              />
            </span>
          )}
        </div>
      </PromiseLoader>
    );
  }
}

const ConnectedStoreSelected = (props) => (
  <Consumer>
    {({
      goToPage,
      location,
      setStoreList,
      setStoreSelected,
      storeData,
      storeSelected,
    }) => (
      <StoreSelected
        {...props}
        goToPage={goToPage}
        location={location}
        setStoreList={setStoreList}
        setStoreSelected={setStoreSelected}
        storeData={storeData}
        storeSelected={storeSelected}
      />
    )}
  </Consumer>
);

export default ConnectedStoreSelected;
