import React from 'react';
import classnames from 'classnames';
import './PanelSkeleton.scss';

export const PanelSkeleton = ({ className }) => (
  <div
    className={classnames({
      your_neimans__skeleton: true,
      [className]: !!className,
      animate: true,
    })}
  />
);

export const GeneralPanelSkeleton = ({ skipImage, numOfMenuItems = 5, className = 'your_neimans__skeleton-general--2' }) => {
  const generateMenuSkeleton = () => {
    const skellyArr = [];
    for (let i = 0; i < numOfMenuItems; i++) {
      skellyArr[i] = <PanelSkeleton key={i} className={className} />;
    }
    return skellyArr;
  };

  return (
    <>
      {!skipImage && <PanelSkeleton className="your_neimans__skeleton-general--1" />}
      {generateMenuSkeleton()}
    </>
  );
};

export default PanelSkeleton;
