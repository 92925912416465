/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState, useEffect } from 'react';
import ModalButtons from './ModalButtons';
import {
  verifySMS,
  verifyPhoneAttribute,
} from '../../../utilities/mfaActionUtils';
import { updateShoppingCart } from '../YourNeimans/components/utilities';
import {
  mfaLoginFailureUtagData,
  mfaLoginSuccessUtagData,
  mfaVerifyPanelUtagData,
} from './utilities';
import { mfaFlows } from './constants';

const formErrors = {
  invalidCode: 'Code is invalid or expired',
  missingValue: 'Please fill all 6-digit confirmation code inputs',
};

const RESEND_CODE_LIMIT_EXCEEDED_MESSAGE = 'Attempt limit exceeded, please try after some time.';

const orderedFields = [
  'codeInput_0',
  'codeInput_1',
  'codeInput_2',
  'codeInput_3',
  'codeInput_4',
  'codeInput_5',
];

/**
 *  This renders the SMS Verification code component
 * @param {*} param0
 * @returns
 */
const VerifySMSCode = ({
  verifypage = false,
  isPanel = false,
  userAttributes,
  mfaContext = {},
  navigateToHelpPage,
  callVerifyPhoneOnLoad,
  toggles,
  closeModal,
  goToLoginPage,
  user,
  updateNameHeaders,
}) => {
  const { phone_number } = userAttributes;

  const codeInput_1 = useRef(null);
  const codeInput_2 = useRef(null);
  const codeInput_3 = useRef(null);
  const codeInput_4 = useRef(null);
  const codeInput_5 = useRef(null);

  const [inputsData, setInputsData] = useState({});
  const [error, setError] = useState(null);
  const { flow } = mfaContext;
  const [isLoading, setLoading] = useState(false);
  const [resendCodeInfoVisible, setResendCodeInfoVisible] = useState(false);
  const [resendCodeLimitExceeded, setResendCodeLimitExceeded] = useState(false);

  const onVerifySendSuccess = (shouldShowResendInfo) => () => {
    if (shouldShowResendInfo) {
      setResendCodeInfoVisible(true);
    }
  };

  const onVerifySendFailure = (err) => {
    if (err.message === RESEND_CODE_LIMIT_EXCEEDED_MESSAGE) {
      setResendCodeInfoVisible(false);
      setResendCodeLimitExceeded(true);
    }
  };

  useEffect(() => {
    if (mfaContext.flow === mfaFlows.signIn || mfaContext.flow === mfaFlows.cartSignIn) {
      mfaVerifyPanelUtagData();
    }
  }, []);

  useEffect(() => {
    if (callVerifyPhoneOnLoad) {
      verifyPhoneAttribute(onVerifySendSuccess(false), onVerifySendFailure);
    }
  }, [callVerifyPhoneOnLoad]);

  const onPaste = (ev) => ev.preventDefault();

  const updateValidationErrors = (isValidForm) => {
    if (!isValidForm) {
      setError(true);
      return;
    }

    setError(null);
  };

  const checkFormValidity = (data) => {
    const inputValues = Object.keys(data).map((e) => data[e]);

    return (
      inputValues.length === 6 && !inputValues.some((value) => value.length === 0)
    );
  };

  const validateForm = (data) => {
    if (!checkFormValidity(data)) {
      updateValidationErrors(false);
    } else updateValidationErrors(true);
  };

  const onChangeInput = (nextInputRef) => (ev) => {
    ev.persist();
    const { value } = ev.target;

    setInputsData((currentInputsData) => {
      const updatedInputsData = {
        ...currentInputsData,
        [ev.target.name]: value.length > 1 ? value.split('')[1] : value,
      };

      if (error) {
        validateForm(updatedInputsData);
      }

      return updatedInputsData;
    });

    if (value.length === 0) {
      return;
    }

    if (nextInputRef && nextInputRef?.current) {
      nextInputRef.current.focus();
    } else {
      ev.target.blur();
    }
  };

  const getFullCode = (data) => orderedFields.reduce((acc, fieldName) => `${acc}${data[fieldName]}`, '');

  const onFailedVerifySMS = () => {
    setError(formErrors.invalidCode);
    setLoading(false);

    if (mfaContext.flow === mfaFlows.signIn || mfaContext.flow === mfaFlows.cartSignIn) {
      mfaLoginFailureUtagData();
    }
  };

  const onSuccessVerifySMS = (user) => {
    if (mfaContext.flow === mfaFlows.signIn || mfaContext.flow === mfaFlows.cartSignIn) {
      window.sessionStorage.setItem(
        'dt_success_login',
        'true'
      ); //  Account page takes this variable for utag tracking

      if (isPanel) {
        const kmsiFlag = localStorage?.getItem('_kmsi') || false;
        const utagMfa = {
          kmsiFlag,
          customerEmail: user?.attributes?.email || '',
        };
        window.sessionStorage.setItem(
          'mfa_login_flag',
          'true'
        ); // mfa_login flag on

        mfaLoginSuccessUtagData(utagMfa);
        updateNameHeaders();
        updateShoppingCart();
        closeModal();

        return;
      }
    }

    const verifyEvent = new CustomEvent('phoneVerfied', {
      detail: { phoneVerfied: true },
    });
    document.dispatchEvent(verifyEvent);
    closeModal();
  };

  const submitForm = () => {
    if (!checkFormValidity(inputsData)) {
      updateValidationErrors(false);
      return;
    }

    const confirmationCode = getFullCode(inputsData);

    setLoading(true);

    verifySMS(
      confirmationCode,
      onSuccessVerifySMS,
      onFailedVerifySMS,
      mfaContext,
      toggles,
      user,
    );
  };

  const getLastFourDigitsOfPhone = () => (!isEmpty(phone_number) ? phone_number?.slice(-4) : '');

  const onClickClose = () => {
    if ((isPanel || verifypage) && mfaContext.flow === mfaFlows.signIn) {
      goToLoginPage();
      return;
    }

    closeModal();
    window?.sessionStorage?.removeItem('verify_phone_mfa');
  };

  const onClickResendCode = () => {
    if (resendCodeLimitExceeded) {
      return;
    }

    verifyPhoneAttribute(onVerifySendSuccess(true), onVerifySendFailure);
  };

  const disabledSubmit = !checkFormValidity(inputsData) || isLoading;

  return (
    <div className="verify-sms-mfa">
      <div className="infoLine">
        <span>
          {
             'Please enter the 6-digit code texted to (XXX) XXX -'
          }
          {` ${getLastFourDigitsOfPhone()}. `}
        </span>
      </div>

      <form className="verifysmscode">
        <input
          name="codeInput_0"
          type="tel"
          aria-label="code input 0"
          value={inputsData?.codeInput_0}
          onPaste={onPaste}
          onChange={onChangeInput(codeInput_1)}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />

        <input
          name="codeInput_1"
          type="tel"
          aria-label="code input 1"
          value={inputsData?.codeInput_1}
          onPaste={onPaste}
          ref={codeInput_1}
          onChange={onChangeInput(codeInput_2)}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />

        <input
          name="codeInput_2"
          type="tel"
          aria-label="code input 2"
          value={inputsData?.codeInput_2}
          onPaste={onPaste}
          ref={codeInput_2}
          onChange={onChangeInput(codeInput_3)}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />

        <input
          name="codeInput_3"
          type="tel"
          aria-label="code input 3"
          value={inputsData?.codeInput_3}
          onPaste={onPaste}
          ref={codeInput_3}
          onChange={onChangeInput(codeInput_4)}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />

        <input
          name="codeInput_4"
          type="tel"
          aria-label="code input 4"
          value={inputsData?.codeInput_4}
          onPaste={onPaste}
          ref={codeInput_4}
          onChange={onChangeInput(codeInput_5)}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />

        <input
          name="codeInput_5"
          type="tel"
          aria-label="code input 5"
          value={inputsData?.codeInput_5}
          onPaste={onPaste}
          ref={codeInput_5}
          onChange={onChangeInput()}
          className={error ? 'inputError' : 'basicInput'}
          inputMode="numeric"
        />
      </form>

      <div className={`resend-or-help ${isPanel ? 'mfa-panel' : ''}`}>
        {(
          flow === mfaFlows.optIn
          || flow === mfaFlows.register
        ) && (
          <span className={resendCodeLimitExceeded ? 'disabled' : ''} onClick={onClickResendCode}>
            Resend Code
          </span>
        )}
        <span className="mfaError">{error}</span>
        {resendCodeInfoVisible && (
          <span className="resend-code-info">Code Sent!</span>
        )}

        {resendCodeLimitExceeded && (
          <>
            <span className="resend-code-info">You’ve requested too many codes.</span>
            <span className="resend-code-info">Please try again after one hour.</span>
          </>
        )}

        {(flow === mfaFlows.signIn || flow === mfaFlows.cartSignIn) && (
          <button className="needHelpButton" aria-label="Need help button " onClick={navigateToHelpPage}>Need Help?</button>
        )}
      </div>
      {(flow === mfaFlows.signIn || flow === mfaFlows.cartSignIn) && <div className="mfa-cancel-text">Click Cancel and sign back in to receive a new code</div>}


      <ModalButtons
        closeButtonTitle="Cancel"
        successButtonTitle="Verify"
        onCloseButtonClick={onClickClose}
        onSuccessButtonClick={submitForm}
        disableSuccessButton={disabledSubmit}
      />
    </div>
  );
};

export default VerifySMSCode;
