export const LEARN_MORE_LINK = 'https://assistance.neimanmarcus.com/privacy';
export const TERMS_N_COND = 'https://assistance.neimanmarcus.com/privacy#securityagainstfraud';

export const REMOVE_PHONE_NUMBER = '/manage-accounts/v1/init-phone-removal/{ucaId}';
export const CONFIRM_REMOVE_PHONE_NUMBER = '/manage-accounts/v1/confirm-phone-removal/{ucaId}';

export const PHONE_MATCH_REGEXP = /^(1|38|)?(\d{3})(\d{3})(\d{4})$/;

export const mfaFlows = {
  optIn: 'Optin',
  optOut: 'Optout',
  signIn: 'Signin',
  cartSignIn: 'CartSignin',
  needHelp: 'NeedHelp',
  register: 'Register',
};
