import React from 'react';
import Store from './Store';
import { Consumer } from '../../PanelContext';
import './StoreDetails.scss';

const StoreDetails = ({ NEW_STORES_UI }) => (
  <Consumer>
    {({ storeSelected, goToPage }) => (
      <div className="panel__storeDetails">
        <h1 className="panel__storeDetails-title">Your Store</h1>
        <div className="panel__storeDetails-store">
          <Store details expanded store={storeSelected} NEW_STORES_UI={NEW_STORES_UI} />
          <button className="panel__storeDetails-selected">
            Your Current Store
          </button>
          <button
            id="changeLink"
            className="panel__storeDetails-change"
            onClick={() => { goToPage('storeListPage'); }}
          >
            Change
          </button>
        </div>
      </div>
    )}
  </Consumer>
);

export default StoreDetails;
