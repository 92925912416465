import React from 'react';
import { Consumer } from '../../PanelContext';
import './Confirmation.scss';

const Confirmation = () => (
  <Consumer>
    {({ email, saSelected, pageStack }) => {
      const prevPage = pageStack[pageStack.length - 2];
      const pageMessage = {
        emailPage: 'email',
        bagPage: 'shopping bag',
      }[prevPage];
      return (
        <div className="panel__confirmation">
          <h1 className="panel__confirmation-title">Thank You</h1>
          <p className="panel__confirmation-content">
            {`Your ${pageMessage} has been sent to ${saSelected.name}! You will receive a
                response at ${email}. Please allow up to 48 hours for your
                style advisor to respond, and make sure to check your junk mail. Thank
                you!`}
          </p>
        </div>
      );
    }}
  </Consumer>
);

export default Confirmation;
