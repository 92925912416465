import React, { Fragment } from 'react';
import {
  Account,
  Bops,
  Confirmation,
  Form,
  Header,
  Panel,
  StoreDetails,
  StoreList,
  StoreSelected,
  StyleAdvisor,
  UpdateZip,
  AccountAccessWrapper,
  ForgotPasswordWrapper,
  InCircle,
  Promo,
} from './components';
import {
  sendEmail,
  sendShoppingBag,
  updateNameHeaders,
  updateShoppingCart,
} from './components/utilities';
import MFAPanelComponent from '../VerifyAccountMFA/MFAPanelComponent';
import { Consumer } from './PanelContext';
import InCircleNmCreditCard from './components/InCircleNmCreditCard/InCircleNmCreditCard';

const pages = (
  currentPage,
  {
    YOUR_NEIMANS_SA,
    GIFTCARDS_PANEL,
    GIFT_CARD_NOTIFICATIONS,
    INCIRCLE_NOTIFICATIONS,
    DT_LOGIN_FLOW,
    UCA_PROFILE_COOKIE,
    EMPLOYEE_STATUS_V2,
    DISCOUNT_ELIGIBILITY_V1,
    LOCK_AUTHSTATUS_API,
    PZP_IDENTITY,
    NEW_STORES_UI,
    DISABLE_ATG_LOGIN,
    UCA_MFA,
    SHOW_INCIRCLE_YEAR,
    VALIDATE_LOGIN,
    NMO_LOYALTY_NEW,
    LOY_LANDING,
  },
  goToPage,
  updateNameHeaders,
  setIncircleData,
  defaultLoading,
  mfaUser
) => ({

  homePage: (
    <Fragment key="homePage">
      <Header showBanner showBack={false} />
      <Account goToPage={goToPage} setIncircleData={setIncircleData} />
      {YOUR_NEIMANS_SA && <StyleAdvisor title="We're Here To Help" />}
      <StoreSelected
        defaultLoading={defaultLoading}
        NEW_STORES_UI={NEW_STORES_UI}
      />
    </Fragment>
  ),
  saPage: (
    <Fragment key="saPage">
      <Header />
      <StyleAdvisor title="Your Style Advisor" />
    </Fragment>
  ),
  emailPage: (
    <Fragment key="emailPage">
      <Header />
      <Form
        title="Email Your Style Advisor"
        cta="Send Email"
        event="email_style_advisor"
        submit={sendEmail}
      />
    </Fragment>
  ),
  bagPage: (
    <Fragment key="bagPage">
      <Header />
      <Form
        title="Send Shopping Bag"
        cta="Send Shopping Bag"
        defaultMessage="Hello, here are the items in my Shopping Bag"
        event="send_your_cart"
        submit={sendShoppingBag}
        optional
      />
    </Fragment>
  ),
  confirmationPage: (
    <Fragment key="confirmationPage">
      <Header showBack={false} />
      <Confirmation />
    </Fragment>
  ),
  storeListPage: (
    <Fragment key="storeListPage">
      <Header />
      <StoreList NEW_STORES_UI={NEW_STORES_UI} />
    </Fragment>
  ),
  storeDetailsPage: (
    <Fragment key="storeDetailsPage">
      <Header />
      <StoreDetails NEW_STORES_UI={NEW_STORES_UI} />
    </Fragment>
  ),
  updateZipPage: (
    <Fragment key="updateZipPage">
      <Header />
      <UpdateZip />
    </Fragment>
  ),
  bopsPage: (
    <Fragment key="bopsPage">
      <Header />
      <Bops NEW_STORES_UI={NEW_STORES_UI} />
    </Fragment>
  ),
  accountAccessPage: (
    <Fragment key="accountAccessPage">
      <Header />
      <AccountAccessWrapper
        goToPage={goToPage}
        updateNameHeaders={updateNameHeaders}
        updateShoppingCart={updateShoppingCart}
        DT_LOGIN_FLOW={DT_LOGIN_FLOW}
        UCA_PROFILE_COOKIE={UCA_PROFILE_COOKIE}
        EMPLOYEE_STATUS_V2={EMPLOYEE_STATUS_V2}
        DISCOUNT_ELIGIBILITY_V1={DISCOUNT_ELIGIBILITY_V1}
        LOCK_AUTHSTATUS_API={LOCK_AUTHSTATUS_API}
        PZP_IDENTITY={PZP_IDENTITY}
        DISABLE_ATG_LOGIN={DISABLE_ATG_LOGIN}
        UCA_MFA={UCA_MFA}
        VALIDATE_LOGIN={VALIDATE_LOGIN}
        NMO_LOYALTY_NEW={NMO_LOYALTY_NEW}
        LOY_LANDING={LOY_LANDING}
      />
    </Fragment>
  ),
  forgotPasswordPage: (
    <Fragment key="forgotPasswordPage">
      <Header />
      <ForgotPasswordWrapper goToPage={goToPage} />
    </Fragment>
  ),
  inCirclePage: (
    <Fragment key="inCirclePage">
      <Header />
      <InCircle
        GIFTCARDS_PANEL={GIFTCARDS_PANEL}
        INCIRCLE_NOTIFICATIONS={INCIRCLE_NOTIFICATIONS}
        SHOW_INCIRCLE_YEAR={SHOW_INCIRCLE_YEAR}
      />
    </Fragment>
  ),
  InCircleNmCreditCard: (
    <Fragment key="InCircleNmCreditCard">
      <Header />
      <InCircleNmCreditCard />
    </Fragment>
  ),
  promoPage: (
    <Fragment key="promoPage">
      <Header />
      <Promo GIFT_CARD_NOTIFICATIONS={GIFT_CARD_NOTIFICATIONS} />
    </Fragment>
  ),
  mfaPage: (
    <Fragment key="mfaPage">
      <Header />
      {UCA_MFA && (
        <MFAPanelComponent
          PZP_IDENTITY={PZP_IDENTITY}
          DISABLE_ATG_LOGIN={DISABLE_ATG_LOGIN}
          UCA_PROFILE_COOKIE={UCA_PROFILE_COOKIE}
          EMPLOYEE_STATUS_V2={EMPLOYEE_STATUS_V2}
          DISCOUNT_ELIGIBILITY_V1={DISCOUNT_ELIGIBILITY_V1}
          goToPage={goToPage}
          user={mfaUser}
          updateNameHeaders={updateNameHeaders}
        />
      )}
    </Fragment>
  ),
  removeMfaPage: (
    <Fragment key="removeMfaPage">
      <Header />
      {UCA_MFA && (
        <MFAPanelComponent
          PZP_IDENTITY={PZP_IDENTITY}
          DISABLE_ATG_LOGIN={DISABLE_ATG_LOGIN}
          UCA_PROFILE_COOKIE={UCA_PROFILE_COOKIE}
          EMPLOYEE_STATUS_V2={EMPLOYEE_STATUS_V2}
          DISCOUNT_ELIGIBILITY_V1={DISCOUNT_ELIGIBILITY_V1}
          removemfa
          goToPage={goToPage}
          user={mfaUser}
          updateNameHeaders={updateNameHeaders}
        />
      )}
    </Fragment>
  ),
}[currentPage]);

const YourNeimans = ({ updateSiteHeader, defaultLoading }) => {
  return (
    <Consumer>
      {({
        pageStack, toggles, goToPage, updateName, setIncircleData, mfaUser,
      }) => (
        <Panel>
          {pages(
            pageStack[pageStack.length - 1],
            toggles,
            goToPage,
            (...props) => updateNameHeaders(updateName, updateSiteHeader, ...props),
            setIncircleData,
            defaultLoading,
            mfaUser,
          )}
        </Panel>
      )}
    </Consumer>
  );
};

export default YourNeimans;
