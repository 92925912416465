/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import ModalButtons from './ModalButtons';
import { confirmPhoneRemoval } from './utilities';
import { mfaFlows } from './constants';

const formErrors = {
  invalidCode: 'Code is invalid or expired',
  missingValue: 'Please fill all 6-digit confirmation code inputs',
};

const orderedFields = [
  'codeInput_0',
  'codeInput_1',
  'codeInput_2',
  'codeInput_3',
  'codeInput_4',
  'codeInput_5',
];

/**
 *  This renders the SMS Verification code component
 * @param {*} param0
 * @returns
 */
const VerifyEmailCode = ({
  isPanel = false,
  mfaContext = {},
  goToLoginPage,
}) => {
  const codeInput_1 = useRef(null);
  const codeInput_2 = useRef(null);
  const codeInput_3 = useRef(null);
  const codeInput_4 = useRef(null);
  const codeInput_5 = useRef(null);

  const [inputsData, setInputsData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const closePanel = () => window?.YourNeimans.setData({ isOpen: false });

  const onPaste = (ev) => ev.preventDefault();

  const updateValidationErrors = (isValidForm) => {
    if (!isValidForm) {
      setError(formErrors.missingValue);
      return;
    }

    setError(null);
  };

  const checkFormValidity = (data) => {
    const inputValues = Object.keys(data).map((e) => data[e]);

    return (
      inputValues.length === 6 && !inputValues.some((value) => value.length === 0)
    );
  };

  const validateForm = (data) => {
    if (!checkFormValidity(data)) {
      updateValidationErrors(false);
    } else updateValidationErrors(true);
  };

  const onChangeInput = (nextInputRef) => (ev) => {
    ev.persist();
    const { value } = ev.target;

    setInputsData((currentInputsData) => {
      const updatedInputsData = {
        ...currentInputsData,
        [ev.target.name]: value.length > 1 ? value.split('')[1] : value,
      };

      if (error) {
        validateForm(updatedInputsData);
      }

      return updatedInputsData;
    });

    if (value.length === 0) {
      return;
    }

    if (nextInputRef && nextInputRef?.current) {
      nextInputRef.current.focus();
    } else {
      ev.target.blur();
    }
  };

  const getFullCode = (data) => orderedFields.reduce((acc, fieldName) => `${acc}${data[fieldName]}`, '');

  const onFailedVerify = () => {
    setError(formErrors.invalidCode);
    setLoading(false);
  };

  const onSuccessVerify = () => {
    setLoading(false);

    const verifyEvent = new CustomEvent('codeVerfied', {
      detail: { removalVerfied: true },
    });
    document.dispatchEvent(verifyEvent);
    closePanel();
  };

  const submitForm = () => {
    if (!checkFormValidity(inputsData)) {
      updateValidationErrors(false);
      return;
    }

    const confirmationCode = getFullCode(inputsData);

    setLoading(true);
    setError(null);

    confirmPhoneRemoval(
      confirmationCode,
      onSuccessVerify,
      onFailedVerify,
    );
  };

  const onClickClose = () => {
    if (isPanel && mfaContext.flow === mfaFlows.signIn) {
      goToLoginPage();
      return;
    }

    closePanel();
    window?.sessionStorage?.removeItem('verify_phone_mfa');
  };

  const disabledSubmit = !checkFormValidity(inputsData) || isLoading;

  return (
    <div className="verify-sms-mfa">
      <div className="infoLine">
        <span>
          To continue, verify your identity by entering the 6-digit code sent
          to your registered email address.
        </span>
      </div>

      <form className="verifysmscode">
        <input
          name="codeInput_0"
          aria-label="code input 0"
          value={inputsData?.codeInput_0}
          onPaste={onPaste}
          onChange={onChangeInput(codeInput_1)}
          className={error ? 'inputError' : 'basicInput'}
        />

        <input
          name="codeInput_1"
          aria-label="code input 1"
          value={inputsData?.codeInput_1}
          onPaste={onPaste}
          ref={codeInput_1}
          onChange={onChangeInput(codeInput_2)}
          className={error ? 'inputError' : 'basicInput'}
        />

        <input
          name="codeInput_2"
          aria-label="code input 2"
          value={inputsData?.codeInput_2}
          onPaste={onPaste}
          ref={codeInput_2}
          onChange={onChangeInput(codeInput_3)}
          className={error ? 'inputError' : 'basicInput'}
        />

        <input
          name="codeInput_3"
          aria-label="code input 3"
          value={inputsData?.codeInput_3}
          onPaste={onPaste}
          ref={codeInput_3}
          onChange={onChangeInput(codeInput_4)}
          className={error ? 'inputError' : 'basicInput'}
        />

        <input
          name="codeInput_4"
          aria-label="code input 4"
          value={inputsData?.codeInput_4}
          onPaste={onPaste}
          ref={codeInput_4}
          onChange={onChangeInput(codeInput_5)}
          className={error ? 'inputError' : 'basicInput'}
        />

        <input
          name="codeInput_5"
          aria-label="code input 5"
          value={inputsData?.codeInput_5}
          onPaste={onPaste}
          ref={codeInput_5}
          onChange={onChangeInput()}
          className={error ? 'inputError' : 'basicInput'}
        />
      </form>

      <div className="mfaError">{error}</div>

      <ModalButtons
        closeButtonTitle="Cancel"
        successButtonTitle="Verify"
        onCloseButtonClick={onClickClose}
        onSuccessButtonClick={submitForm}
        disableSuccessButton={disabledSubmit}
      />
    </div>
  );
};

export default VerifyEmailCode;
